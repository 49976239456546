import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import InventoryTable from "components/Inventory/InventoryTable"
import GridHeader from "components/common/GridHeader"
import { ReducerContext } from "App"

const Inventory = () => {
  const { state: { user: { permissions } } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])

  return (
    <Grid container className="GridContainer">
      <GridHeader h4="Inventory List" p="Masterview of current inventory.">
        <InventoryTable />
      </GridHeader>
    </Grid>
  );
}

export default Inventory
