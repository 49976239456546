import React, { useContext } from "react"
import MaterialTable from 'material-table'
import { CircularProgress } from '@material-ui/core'
import ErrorDialog from 'components/common/ErrorDialog'
import PatchedPagination from "components/common/PatchedPagination"
import { commas, createDateString, useRestApi } from 'helpers'
import { ReducerContext } from "App"

const InventoryInteractionsTable = () => {
  const { state: { currentTerminal } } = useContext(ReducerContext)
  const columns = [
    { title: "Inventory Location Name", field: "inventory_id" },
    { title: "Created At", field: "created_at", defaultSort: "desc", render: row => createDateString(row.created_at) },
    { title: "Bill Of Lading ID", field: "bill_of_lading_id" },
    { title: "Sand Type", field: "sand_type" },
    { title: "Gross Weight", field: "gross_weight", render: row => commas(row.gross_weight) },
  ];
  const { data, loading, error } = useRestApi('api/invinter')

  return <>
    {loading && <CircularProgress />}
    {error && <ErrorDialog error={"error"} />}
    {data && <MaterialTable
      title="Inventory Interactions"
      columns={columns}
      data={data.filter(inv => inv.terminal === currentTerminal)}
      components={{ Pagination: PatchedPagination }}
      options={{ paginationType: "normal", filtering: true, pageSize: 10, exportButton: true, headerStyle: { fontSize: '14px' } }}
    />}
  </>
}

export default InventoryInteractionsTable;
