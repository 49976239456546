import React from "react"
import { TextField, Grid, Dialog, FormControlLabel, Switch } from "@material-ui/core"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"
import { useForm } from "helpers"

const SettingsTableModify = ({ open, handleClose, update, specificData, categoryTitle }) => {
  useStyles()
  const { setValue, values, isDirty } = useForm(specificData)

  const handleSubmit = () => {
    const { tableData, __typename, enabled, ...rest } = values
    isDirty && update([rest])
    handleClose()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <Grid container className="GridContainer">
          <GridHeader h4={`Update ${categoryTitle}`}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} className="GridItem">
                <TextField
                  id={"type"}
                  label={categoryTitle}
                  value={values["type"]}
                  onChange={({ target: { id, value } }) => setValue(id, value)}
                  margin="normal"
                  required
                />
              </Grid >
              <Grid item xs={12} sm={12} md={12} className="GridItem">
                <FormControlLabel
                  control={
                    <Switch
                      checked={!values["archived_at"]}
                      onChange={() => {
                        if (values["archived_at"]) {
                          setValue("archived_at", null)
                        } else {
                          setValue("archived_at", new Date().toISOString())
                        }
                      }}
                      name="Enable"
                      color="primary"
                    />
                  }
                  labelPlacement="end"
                  label="Enable"
                />
              </Grid>
            </Grid>
            <Button color="primary" type="button" onClick={handleSubmit}>Update</Button>
          </GridHeader>
        </Grid>
      </Dialog>
    </>
  )
}

export default SettingsTableModify