import React, { useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { CircularProgress, Grid } from "@material-ui/core"
import BOLTable from "components/BOL/BOLTable"
import ErrorDialog from "components/common/ErrorDialog"
import GridHeader from "components/common/GridHeader"
import { useGqlDirect } from "helpers"
import { queries } from "queries"
import useStyles from "assets/jss/material-global-styles"
import { ReducerContext } from "App"

const BOLs = ({ active }) => {
  useStyles()
  const { state: { currentTerminal, user: { permissions } } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])

  const activePOs = useGqlDirect(queries.GET_ACTIVE_PURCHASE_ORDERS)

  return (
    <Grid container className="GridContainer">
      <GridHeader h4={`${active ? "Active / Outbound Bol" : "Historical"} Management`} p={`Masterview for ${active ? "active" : "history"} Bills of Lading.`}>
        {activePOs.loading && <CircularProgress />}
        {activePOs.error && <ErrorDialog error={"error"} />}
        {activePOs.data && <BOLTable activePOsData={activePOs.data.pos.filter(po => po.terminal === currentTerminal)} active={active} />}
      </GridHeader>
    </Grid>
  )
}

export default BOLs
