import axios from "axios"

(async () => {
  try {
    const { data } = await axios.get(`${window.location.protocol}//${window.location.host}/config/config.json`)
    Object.entries(data).map(([key, val]) => localStorage.setItem(key, val))
    const { start } = require("./indexApp")
    start()
  } catch (error) {
    console.log('error: ', error)
  }
})()