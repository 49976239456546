import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import GridHeader from "components/common/GridHeader"
import { ReducerContext } from "App"

const Inventory = () => {
  const { state: { user: { permissions } } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { permissions.includes('user') && history.push("/") }, [permissions, history])
  return (
    <Grid container className="GridContainer">
      <GridHeader h4="Unauthorized User" p="You are not allowed to view this content.">
        <p>You have authenticated into the system but you are not authorized to view this content.</p>
        <p>Please reach out to the Logitrac Admins to have permissions granted.</p>
      </GridHeader>
    </Grid>
  )
}

export default Inventory
