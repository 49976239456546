import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core"
import PurchaseOrdersTable from "components/PurchaseOrder/PurchaseOrdersTable"
import GridHeader from "components/common/GridHeader"
import { ReducerContext } from "App"

function PurchaseOrders() {
  const { state: { user: { permissions } } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])

  return (
    <Grid container className="GridContainer">
      <GridHeader h4="Purchase Orders List" p="Masterview for recent Purchase Orders.">
        <PurchaseOrdersTable />
      </GridHeader>
    </Grid>
  )
}


export default PurchaseOrders
