import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import Button from "@material-ui/core/Button"
import buttonStyle from "assets/jss/material-dashboard-react/components/buttonStyle.jsx"

const RegularButton = ({
  classes,
  color,
  round,
  children,
  disabled,
  simple,
  size,
  block,
  link,
  justIcon,
  className,
  muiClasses,
  ...rest
}) => {
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  })
  return (
    <Button {...rest} classes={muiClasses} className={btnClasses}>
      {children}
    </Button>
  );
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  // use this to pass the classes props from Material-UI
  muiClasses: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(buttonStyle)(RegularButton);
