import React, { useContext } from "react"
import DateFnsUtils from "@date-io/date-fns"
import { TextField, Select, InputLabel, Grid, FormControl, MenuItem, Dialog } from "@material-ui/core"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"
import { commas, noCommas, useForm } from "helpers"
import { ReducerContext } from "App"
import axios from "axios"

const PurchaseOrderEdit = ({
  open,
  handleClose,
  specificPoData: {
    id,
    supplier,
    buyer,
    sand_type,
    max_quantity_to_ship,
    purchase_order_number,
    start_time,
    end_time,
    contact_info,
    transloading_client,
    external_reference_number,
    job_name,
    quantity_shipped,
    status
  },
  getPOs,
  sqlParams
}) => {
  useStyles()
  const { state: { user: { id: dbUserId }, constsArrays: { clientName, sandType }, currentTerminal } } = useContext(ReducerContext)

  const init = {
    id,
    supplier,
    buyer,
    sand_type,
    max_quantity_to_ship: commas(max_quantity_to_ship),
    purchase_order_number,
    start_time,
    end_time,
    contact_info: contact_info || "",
    transloading_client,
    external_reference_number,
    job_name,
    quantity_shipped,
    status
  }

  const { setValue, values, isDirty } = useForm(init);

  const handleDate = (date, type) => {
    if (date && !isNaN(date)) setValue(type, date.toISOString())
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (isDirty) {
      const { __typename, max_quantity_to_ship, contact_info, ...rest } = values

      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
          {
            array: [{ ...rest, max_quantity_to_ship: noCommas(max_quantity_to_ship), updated_at: new Date().toISOString(), contact_info: contact_info === "" ? null : contact_info }],
            dbTable: "Purchase_Orders",
            dbEvent: "update",
            dbUnique: "id",
            dbUserId
          }
        )
        getPOs({ ...sqlParams, page_number: 0, currentTerminal })
      } catch (error) {
        console.log('error: ', error);
      }
    }
    handleClose()
  }

  const textFields = {
    top: [{
      id: "purchase_order_number",
      label: "Purchase Order Number",
      helperText: "This number will be used to identify the PO on Bills of Lading.",
      disabled: true
    },
    { id: "external_reference_number", label: "External Reference Number" },
    {
      id: "supplier",
      label: "Supplier",
      helperText: "The Supplier is the company that owns the sand while it is in stock."
    },
    {
      id: "buyer",
      label: "Buyer",
      helperText: "The Buyer is the company that will take title of the sand upon pickup. This might be the well's Producer."
    },
    { id: "job_name", label: "Job Name", helperText: "The name of the well." }],
    quantity: {
      id: "max_quantity_to_ship",
      label: "Max Quantity to Ship",
      onChange: e => setValue(e.target.id, commas(e.target.value))
    },
    contact: {
      id: "contact_info",
      label: "Contact Information",
      helperText: "Phone Number, email, names, details etc."
    }
  }

  const makeField = ({ id, label, helperText, onChange, disabled }) => (
    <Grid item xs={12} sm={12} md={12} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        helperText={helperText}
        value={values[id]}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "contact_info"}
        disabled={disabled}
      />
    </Grid >
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
      >
        <Grid container className="GridContainer">
          <GridHeader h4="Update Purchase Order" p="Please complete all details below.">
            <form onSubmit={e => handleSubmit(e)}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} className="GridItem">
                  <FormControl fullWidth required>
                    <InputLabel>Status</InputLabel>
                    <Select
                      name="status"
                      onChange={e => setValue(e.target.name, e.target.value)}
                      value={values["status"]}
                      required
                    >
                      <MenuItem key={"open"} value={"open"}>Open</MenuItem>
                      <MenuItem key={"closed"} value={"closed"}>Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid >
                {textFields.top.map(field => makeField(field))}
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} className="GridItem">
                  <FormControl fullWidth required>
                    <InputLabel>Transloading Client</InputLabel>
                    <Select
                      name="transloading_client"
                      onChange={e => setValue(e.target.name, e.target.value)}
                      value={values["transloading_client"]}
                      required
                    >
                      {clientName.map((clientName, index) => (<MenuItem key={index} value={clientName}>{clientName}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid >
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }} className="GridItem">
                  <FormControl fullWidth required>
                    <InputLabel>Sand Type</InputLabel>
                    <Select
                      name="sand_type"
                      onChange={e => setValue(e.target.name, e.target.value)}
                      value={values["sand_type"]}
                      required
                    >
                      {sandType.map(sandType => (<MenuItem key={sandType} value={sandType}>{sandType}</MenuItem>))}
                    </Select>
                  </FormControl>
                </Grid >
                {makeField(textFields.quantity)}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={12} md={6} style={{ marginTop: 20 }} className="GridItem">
                    <KeyboardDatePicker
                      margin="normal"
                      id="start-date-picker-dialog"
                      label="Start Date"
                      format="MM/dd/yyyy"
                      value={values["start_time"]}
                      onChange={e => handleDate(e, "start_time")}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      autoOk={true}
                      error={(new Date(values["start_time"]) - new Date(values["end_time"])) > 0}
                      helperText={((new Date(values["start_time"]) - new Date(values["end_time"])) > 0) ? ("Start date cannot be greater than end date") : ""}
                      required
                      fullWidth
                    />
                  </Grid >
                  <Grid item xs={12} md={6} style={{ marginTop: 20 }} className="GridItem">
                    <KeyboardDatePicker
                      margin="normal"
                      id="end-date-picker-dialog"
                      label="End Date"
                      format="MM/dd/yyyy"
                      value={values["end_time"]}
                      onChange={(e) => handleDate(e, "end_time")}
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      autoOk={true}
                      required
                      fullWidth
                    />
                  </Grid >
                </MuiPickersUtilsProvider>
                {makeField(textFields.contact)}
              </Grid>
              <Button
                color="primary"
                type="submit"
                value="submit"
                disabled={(new Date(values["start_time"]) - new Date(values["end_time"])) > 0}
              >
                Update Purchase Order
              </Button>
            </form>
          </GridHeader>
        </Grid>
      </Dialog>
    </div>
  );
}

export default PurchaseOrderEdit