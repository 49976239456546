import React, { useContext } from "react"
import { AppBar, Toolbar, IconButton, Hidden, Icon, Typography, Select, MenuItem } from "@material-ui/core"
import { Menu, Person, Business } from "@material-ui/icons"
import useStyles from "assets/jss/material-dashboard-react/components/headerStyle"
import { actionGenerators } from "reducer"
import { ReducerContext } from "App"
import axios from "axios"

const Navbar = ({ toggle }) => {
  const classes = useStyles()
  const { dispatch, state: { user: { id: dbUserId, terminals }, user, currentTerminal } } = useContext(ReducerContext)

  const changeTerminal = async currentTerminal => {
    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
        {
          array: [{ id: dbUserId, current_terminal: currentTerminal }],
          dbTable: "users",
          dbEvent: "update",
          dbUnique: "id",
          dbUserId
        }
      )
      dispatch(actionGenerators.update({ currentTerminal }))
    } catch (error) {
      console.log('error: ', error);
    }
  }

  return (
    <AppBar className={`${classes.appBar} ${classes.green}`}>
      <Toolbar className={classes.container}>
        <div className={`${classes.flex} ${classes.iconAlign}`}>
          <Icon className={classes.iconRightMargin}>
            <Business color="primary" />
          </Icon>
          {terminals.length < 2 ?
            <Typography variant="h6"><strong>{terminals[0] ? terminals[0] : ""}</strong></Typography>
            :
            <Select
              name="Terminal"
              onChange={e => changeTerminal(e.target.value)}
              value={currentTerminal}
            >{terminals.map((terminal, ind) => <MenuItem key={ind} value={terminal}>{terminal}</MenuItem>)}
            </Select>}
        </div>
        <Hidden smDown>
          <Typography variant="h6"><strong>{`${user?.first_name} ${user?.last_name}`}</strong></Typography>
          <Icon className={classes.iconLeftMargin}>
            <Person color="primary" />
          </Icon>
        </Hidden>
        <Hidden mdUp>
          <IconButton color="inherit" onClick={toggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
