import React, { useState, useContext, useMemo, useEffect } from "react"
import { Edit, Print, CheckCircle, Cancel, AssignmentTurnedIn } from "@material-ui/icons"
import { IconButton, CircularProgress } from "@material-ui/core"
import BOLPrint from "components/BOL/BOLPrint"
import BOLEdit from "components/BOL/BOLEdit"
import BOLCheckout from "components/BOL/BOLCheckout"
import ErrorDialog from "components/common/ErrorDialog"
import { commas, createDateString, useLazyGqlDirect, useRestApiPost, useLazyRestApiPost, useDebounce } from "helpers"
import { queries } from "queries"
import { ReducerContext } from "App"
import MUIDataTable from "mui-datatables"

const BOLTable = ({ activePOsData, active }) => {
  const { state: { currentTerminal } } = useContext(ReducerContext)
  const [loadInvInter, { data: specificInvInterData, loading: specificInvInterLoading, error: specificInvInterError }] = useLazyGqlDirect(queries.GET_SPEC_INV_INTER)
  const [loadInventory, { data: inventoryData, loading: inventoryLoading, error: inventoryError }] = useLazyGqlDirect(queries.GET_SPECIFIC_TERMINAL_INVENTORY_IDS)

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([])
  const [printOpen, setPrintOpen] = useState(false);
  const [printBOLData, setPrintBOLData] = useState(null);
  const [specificBolData, setSpecificBolData] = useState(null)
  const [specificPoData, setSpecificPoData] = useState(null)

  const initSqlParams = useMemo(() => ({
    search_text: null,
    page_number: 0,
    sort: { column: 'created_at', direction: 'desc' },
    filter: {},
    num_per_page: 10,
    currentTerminal,
    active
  }), [currentTerminal, active])

  const [sqlParams, setSqlParams] = useState(initSqlParams)

  const { data: BOLsData, loading: BOLsLoading, error: BOLsError } = useRestApiPost('api/bols', initSqlParams)

  useEffect(() => {
    BOLsData && setData(BOLsData)
  }, [BOLsData])

  const [getBOLs, { data: getBOLsData, loading: getBOLsLoading, error: getBOLsError }] = useLazyRestApiPost('api/bols')

  useEffect(() => {
    getBOLsData && setData(getBOLsData)
  }, [getBOLsData])

  const getBOLsDebounced = useDebounce(getBOLs, 1000)

  const handleClose = () => setOpen(false)
  const handlePrintClose = () => setPrintOpen(false)
  const printCheckboxHandler = ({ printCheckbox, printData }) => {
    setOpen(false)
    setPrintOpen(printCheckbox)
    setPrintBOLData(printData)
  }

  const handleClick = rowIndex => {
    const { bolId: bol_id, id, sand_type, purchase_order_number, external_reference_number, job_name, transloading_client, buyer, supplier, quantity_shipped } = data[rowIndex]

    loadInvInter({ bol_id })
    loadInventory({ transloading_client, sand_type })
    setSpecificBolData(data[rowIndex])
    setSpecificPoData({ id, sand_type, purchase_order_number, external_reference_number, job_name, transloading_client, buyer, supplier, quantity_shipped })
    setOpen(true)
  }

  const handlePrintClick = rowIndex => {
    const { bolId: bol_id } = data[rowIndex]

    loadInvInter({ bol_id })
    setPrintBOLData(data[rowIndex])
    setPrintOpen(true)
  }

  const columns = [
    {
      name: "", options: {
        filter: false, sort: false, empty: true, display: active, customBodyRenderLite: (_, rowIndex) =>
        (<IconButton onClick={() => handleClick(rowIndex)}>
          <AssignmentTurnedIn />
        </IconButton>)
      }
    },
    {
      name: "", options: {
        filter: false, sort: false, empty: true, display: !active, customBodyRenderLite: (_, rowIndex) =>
        (<IconButton onClick={() => handlePrintClick(rowIndex)}>
          <Print />
        </IconButton>)
      }
    },
    {
      name: "", options: {
        filter: false, sort: false, empty: true, display: !active, customBodyRenderLite: (_, rowIndex) =>
        (<IconButton onClick={() => handleClick(rowIndex)}>
          <Edit />
        </IconButton>)
      }
    },
    { label: "BOL ID #", name: "bolId" },
    {
      label: "Status", name: "status", options: {
        customBodyRender: status => {
          switch (status) {
            case "started":
              return <strong>{status}</strong>
            case "completed":
              return <CheckCircle style={{ fill: "#4caf50", fontSize: "30px" }} />
            case "cancelled":
              return <Cancel style={{ fill: "rgb(239, 83, 80)", fontSize: "30px" }} />
            default:
              return
          }
        }
      }
    },
    { label: "Driver's Name", name: "driver_name" },
    { label: "Product", name: "sand_type" },
    { label: "Trailer Number", name: "trailer_number" },
    { label: "Truck Number", name: "truck_number" },
    { label: "Carrier", name: "carrier" },
    { label: "Tare Weight", name: "tare_weight", options: { customBodyRenderLite: (_, rowIndex) => commas(data[rowIndex].tare_weight) } },
    { label: "External Reference Number", name: "external_reference_number" },
    { label: "Job Name", name: "job_name" },
    { label: "Created At", name: "created_at", options: { customBodyRenderLite: (_, rowIndex) => createDateString(data[rowIndex].created_at) } }
  ]

  const col_names = columns.map(col => col.name)

  const options = {
    selectableRows: 'none',
    searchAlwaysOpen: true,
    print: false,
    download: false,
    viewColumns: false,
    filterType: 'textField',
    serverSide: true,
    page: sqlParams.page_number,
    count: data[0] ? data[0].total_rows : 0,
    rowsPerPage: sqlParams.num_per_page,
    rowsPerPageOptions: [10, 25, 50, 100],
    customToolbar: () => <>{(getBOLsLoading || BOLsLoading) && <CircularProgress />}</>,
    onChangePage: page_number => {
      setSqlParams({ ...sqlParams, page_number })
      getBOLs({ ...sqlParams, page_number, currentTerminal })
    },
    onChangeRowsPerPage: num_per_page => {
      setSqlParams({ ...sqlParams, num_per_page })
      getBOLs({ ...sqlParams, num_per_page, currentTerminal })
    },
    onColumnSortChange: (column, direction) => {
      setSqlParams({ ...sqlParams, sort: { column, direction }, page_number: 0 })
      getBOLs({ ...sqlParams, sort: { column, direction }, page_number: 0, currentTerminal })
    },
    onFilterChange: (_, filterList) => {
      setSqlParams({ ...sqlParams, filter: Object.fromEntries(filterList.flatMap((item, ind) => item[0] ? [[col_names[ind], item[0]]] : [])), page_number: 0 })
      getBOLsDebounced({ ...sqlParams, currentTerminal, filter: Object.fromEntries(filterList.flatMap((item, ind) => item[0] ? [[col_names[ind], item[0]]] : [])), page_number: 0 })
    },
    onSearchChange: search_text => {
      setSqlParams({ ...sqlParams, search_text, page_number: 0 })
      getBOLsDebounced({ ...sqlParams, currentTerminal, search_text, page_number: 0 })
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const dataFormatted = data.map(item => {
        const [, ...data] = item.data
        return { ...item, data }
      })

      return buildHead(columns.filter(item => item.label !== 'Actions')) + buildBody(dataFormatted)
    }
  }

  return (
    <>
      {(BOLsError || getBOLsError) && <ErrorDialog error={"error"} />}
      <MUIDataTable title={<CircularProgress />} {...{ data, columns, options }} />

      {(specificInvInterLoading || inventoryLoading) && <CircularProgress />}
      {(specificInvInterError || inventoryError) && <ErrorDialog error={specificInvInterError || inventoryError} />}
      {open && (active ?
        <BOLCheckout
          invInter={specificInvInterData ? specificInvInterData.invInters : []}
          inventoryData={inventoryData ? inventoryData.termInv.filter(inv => inv.terminal === currentTerminal) : []}
          specificBolData={specificBolData}
          specificPoData={specificPoData}
          open={open}
          handleClose={handleClose}
          activePOsData={activePOsData}
          loadInvInter={loadInvInter}
          loadInventory={loadInventory}
          printCheckboxHandler={printCheckboxHandler}
          getBOLs={getBOLs}
          sqlParams={sqlParams}
          specificInvInterLoading={specificInvInterLoading}
        />
        :
        <BOLEdit
          invInter={specificInvInterData ? specificInvInterData.invInters : []}
          getBOLs={getBOLs}
          sqlParams={sqlParams}
          open={open}
          handleClose={handleClose}
          specificBolData={specificBolData}
        />)}
      {(printOpen && printBOLData && specificInvInterData) &&
        <BOLPrint
          bolData={printBOLData}
          handleClose={handlePrintClose}
          open={printOpen}
          specificInvInterData={specificInvInterData}
        />}
    </>
  )
}

export default BOLTable;
