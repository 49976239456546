import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import DateFnsUtils from "@date-io/date-fns"
import { TextField, Select, InputLabel, Grid, FormControl, MenuItem, CircularProgress } from "@material-ui/core"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"
import ErrorDialog from "components/common/ErrorDialog"
import useStyles from "assets/jss/material-global-styles"
import { commas, noCommas, useForm, useRestApi } from "helpers"
import { ReducerContext } from "App"
import axios from "axios"

const init = {
  supplier: "",
  buyer: "",
  sand_type: "",
  max_quantity_to_ship: "",
  purchase_order_number: "",
  start_time: new Date(new Date().setHours(0, 0, 0, 0)),
  end_time: new Date(new Date().setHours(23, 59, 59, 59) + (24 * 3600 * 1000)),
  contact_info: "",
  transloading_client: "",
  external_reference_number: "",
  job_name: ""
}

const PurchaseOrderCreate = () => {
  const { state: { user: { id: dbUserId, permissions }, currentTerminal } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])

  useStyles();
  const { state: { constsArrays: { clientName, sandType } } } = useContext(ReducerContext)
  const { setValue, values } = useForm(init);

  const { data: POsData, loading: POsLoading, error: POsError } = useRestApi(`api/pos_create?currentTerminal=${currentTerminal}`)

  const handleDate = (date, type) => {
    if (date && !isNaN(date)) setValue(type, date.toISOString())
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const { max_quantity_to_ship, ...rest } = values

    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/insert`,
        {
          item: { ...rest, max_quantity_to_ship: noCommas(max_quantity_to_ship), terminal: currentTerminal },
          dbTable: "Purchase_Orders",
          dbEvent: "insert",
          dbUserId
        }
      )
      history.push("/purchase-orders-list")
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const textFields = {
    top: [{
      id: "purchase_order_number",
      label: "Purchase Order Number",
      helperText: POsData?.some(po => values["purchase_order_number"] === po.purchase_order_number) ? "PO number already used. Please use a different number." : "This number will be used to identify the PO on Bills of Lading.",
      error: POsData?.some(po => values["purchase_order_number"] === po.purchase_order_number)
    },
    { id: "external_reference_number", label: "External Reference Number" },
    {
      id: "supplier",
      label: "Supplier",
      helperText: "The Supplier is the company that owns the sand while it is in stock."
    },
    {
      id: "buyer",
      label: "Buyer",
      helperText: "The Buyer is the company that will take title of the sand upon pickup. This might be the well's Producer."
    },
    { id: "job_name", label: "Job Name", helperText: "The name of the well." }],
    quantity: {
      id: "max_quantity_to_ship",
      label: "Max Quantity to Ship",
      onChange: e => setValue(e.target.id, commas(e.target.value))
    },
    contact: {
      id: "contact_info",
      label: "Contact Information",
      helperText: "Phone Number, email, names, details etc."
    }
  }

  const makeField = ({ id, label, helperText, onChange, error }) => (
    <Grid item xs={12} sm={12} md={12} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        helperText={helperText}
        value={values[id]}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "contact_info"}
        error={error}
      />
    </Grid >
  )

  return (
    <>
      {POsLoading && <CircularProgress />}
      {POsError?.map(err => <ErrorDialog error={err} />)}
      {POsData &&
        <>
          <Grid container className="GridContainer">
            <GridHeader h4="Create New Purchase Order" p="Please complete all details below.">
              <form onSubmit={e => handleSubmit(e)}>
                <Grid container>
                  {textFields.top.map(field => makeField(field))}
                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} className="GridItem">
                    <FormControl fullWidth required>
                      <InputLabel>Transloading Client</InputLabel>
                      <Select
                        name="transloading_client"
                        onChange={e => setValue(e.target.name, e.target.value)}
                        value={values["transloading_client"]}
                        fullWidth
                        required
                      >
                        {clientName.map((ClientName, index) => <MenuItem key={index} value={ClientName}>{ClientName}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Grid >
                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }} className="GridItem">
                    <FormControl fullWidth required>
                      <InputLabel>Sand Type</InputLabel>
                      <Select
                        name="sand_type"
                        onChange={e => setValue(e.target.name, e.target.value)}
                        value={values["sand_type"]}
                        fullWidth
                        required
                      >
                        {sandType.map(sandType => (<MenuItem key={sandType} value={sandType}>{sandType}</MenuItem>))}
                      </Select>
                    </FormControl>
                  </Grid >
                  {makeField(textFields.quantity)}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid item xs={12} md={6} style={{ marginTop: 20 }} className="GridItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="start-date-picker-dialog"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        value={values["start_time"]}
                        onChange={e => handleDate(e, "start_time")}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        autoOk={true}
                        error={(new Date(values["start_time"]) - new Date(values["end_time"])) > 0}
                        helperText={((new Date(values["start_time"]) - new Date(values["end_time"])) > 0) ? ("Start date cannot be greater than end date") : ""}
                        required
                        fullWidth
                      />
                    </Grid >
                    <Grid item xs={12} md={6} style={{ marginTop: 20 }} className="GridItem">
                      <KeyboardDatePicker
                        margin="normal"
                        id="end-date-picker-dialog"
                        label="End Date"
                        format="MM/dd/yyyy"
                        value={values["end_time"]}
                        onChange={(e) => handleDate(e, "end_time")}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        autoOk={true}
                        required
                        fullWidth
                      />
                    </Grid >
                  </MuiPickersUtilsProvider>
                  {makeField(textFields.contact)}
                </Grid>
                <Button
                  color="primary"
                  type="submit"
                  value="submit"
                  disabled={(new Date(values["start_time"]) - new Date(values["end_time"])) > 0 || POsData?.some(po => values["purchase_order_number"] === po.purchase_order_number)}
                >
                  Submit Purchase Order
                </Button>
              </form>
            </GridHeader>
          </Grid>
        </>}
    </>
  )
}

export default PurchaseOrderCreate
