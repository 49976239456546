import React from 'react';
import { List, ListItem, ListItemText, IconButton, Grid, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'

const SettingsLinks = ({ label, onClick, settings, permissionIndex }) => {
  return (
    <div>
      <Grid container className="GridContainer">
        <Grid item xs={12} md={6} className="GridItem">
          <Typography variant="subtitle1">{`${label} Settings`}</Typography>
          <div>
            <List dense>
              {settings.map(({ label }, ind) => <ListItem onClick={() => onClick(permissionIndex, ind)} key={ind}>
                <IconButton>
                  <Edit />
                </IconButton>
                <ListItemText
                  primary={`${label}`}
                />
              </ListItem>)}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SettingsLinks