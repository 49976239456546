import { authContext } from "indexApp"
import axios from "axios"

const actionList = ['UPDATE', 'SET_ALL_CONSTS', 'INIT']

export const initialState = {
  reset: true,
  constsArrays: null,
  constsObjs: null,
  user: '',
  currentTerminal: ''
}

const actions = actionList.reduce((list, action) => ({ ...list, [action]: action }), {})

export const actionGenerators = {
  update: payload => ({ type: actions.UPDATE, payload }),
  setAllConsts: payload => ({ type: actions.SET_ALL_CONSTS, payload }),
  init: payload => ({ type: actions.INIT, payload })
}


const handlers = {
  [actions.UPDATE]: (state, { payload }) => ({ ...state, ...payload }),
  [actions.SET_ALL_CONSTS]: (state, { payload: { constants } }) => {
    const allConstCategories = constants.reduce((acc, cur) => ({ ...acc, [cur.category]: [...(acc[cur.category] ? acc[cur.category] : []), cur] }), {})
    const allConstsArrays = constants.reduce((acc, { category, type }) => ({ ...acc, [category]: [...(acc[category] ? acc[category] : []), type] }), {})
    const allConstsObjs = Object.fromEntries(Object.entries(allConstsArrays).map(([key, val]) => [key, val.reduce((acc, cur, ind) => ({ ...acc, [ind]: cur }), {})]))
    return ({ ...state, allConstsArrays, allConstsObjs, allConstCategories })
  },
  [actions.INIT]: (state, { payload: { constants: { constants }, allUsers: { allUsers }, reset } }) => {
    const constCategories = constants.reduce((acc, cur) => ({ ...acc, [cur.category]: [...(acc[cur.category] ? acc[cur.category] : []), cur] }), {})
    const constsArrays = constants.reduce((acc, { category, type }) => ({ ...acc, [category]: [...(acc[category] ? acc[category] : []), type] }), {})
    const constsObjs = Object.fromEntries(Object.entries(constsArrays).map(([key, val]) => [key, val.reduce((acc, cur, ind) => ({ ...acc, [ind]: cur }), {})]))
    const { profile: { oid, family_name, given_name }, userName } = authContext.getCachedUser()
    const [existingUser] = allUsers.filter(user => user.email.toLowerCase() === userName.toLowerCase())

    if (existingUser) {
      return { ...state, reset, constsArrays, constsObjs, constCategories, user: existingUser, allUsers, currentTerminal: existingUser?.current_terminal ? existingUser?.current_terminal : existingUser.terminals[0] ? existingUser.terminals[0] : '' }
    } else {
      const dbUser = { email: userName, first_name: given_name, last_name: family_name, permissions: '{}', terminals: '{}', id: oid }
      const user = { email: userName, first_name: given_name, last_name: family_name, permissions: [], terminals: [], id: oid }
      try {
        axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/insert`,
          {
            item: dbUser,
            dbTable: "users",
            dbEvent: "insert",
            dbUserId: oid
          }
        )
      } catch (error) {
        console.log('error: ', error);
      }

      return { ...state, reset, constsArrays, constsObjs, constCategories, user, allUsers }
    }
  }
}

export const reducer = (state = initialState, action) => {
  return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state
}