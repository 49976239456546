import React, { useContext } from "react"
import axios from "axios"
import { TextField, Select, InputLabel, Grid, FormControl, MenuItem, Dialog } from "@material-ui/core"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"
import useStyles from "assets/jss/material-global-styles"
import { commas, noCommas, useForm } from "helpers"
import { ReducerContext } from "App"

const InventoryEdit = ({
  open,
  handleClose,
  getTermInv,
  sqlParams,
  specificInventoryData: {
    id: invID,
    inventory_id,
    owner,
    quantity,
    sand_type,
    storage_type,
    status,
    transloading_client,
    source_location
  }
}) => {
  useStyles();
  const { state: { user: { id: dbUserId }, constsArrays: { sandType, clientName, storageType, inventoryStatus }, currentTerminal } } = useContext(ReducerContext)

  const init = {
    invID,
    inventory_id,
    owner,
    quantity,
    sand_type,
    storage_type,
    status,
    transloading_client,
    source_location
  }
  const { setValue, values, isDirty } = useForm(init);

  const handleForm = async () => {
    if (isDirty) {
      const { invID: id, quantity, ...rest } = values
      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/upsert`,
          {
            array: [{
              ...rest,
              id,
              quantity: noCommas(quantity),
              updated_at: new Date().toISOString()
            }],
            dbTable: "Terminal_Inventory",
            dbEvent: "update",
            dbUnique: "id",
            dbUserId
          }
        )

        getTermInv({ ...sqlParams, page_number: 0, currentTerminal })
        handleClose()
      } catch (error) {
        console.log('error: ', error);
      }
    }

    handleClose()
  }

  const makeField = ({ id, label, onChange, value }) => (
    <Grid item xs={12} sm={12} md={12} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        value={value ? value : values[id]}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required
      />
    </Grid >
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="xl"
      >
        <Grid container className="GridContainer">
          <GridHeader h4="Edit Inventory Item" p="Please complete all details below.">
            <Grid container>
              {makeField({ id: "inventory_id", label: "Invenotry ID" })}
              {makeField({ id: "owner", label: "Owner" })}
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} className="GridItem">
                <FormControl fullWidth required>
                  <InputLabel>Storage Type</InputLabel>
                  <Select
                    name="storage_type"
                    onChange={e => setValue(e.target.name, e.target.value)}
                    value={values["storage_type"]}
                    fullWidth
                    required
                  >
                    {storageType.map((storageType, ind) => <MenuItem key={ind} value={storageType}>{storageType}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid >
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }} className="GridItem">
                <FormControl fullWidth required>
                  <InputLabel>Sand Type</InputLabel>
                  <Select
                    name="sand_type"
                    onChange={e => setValue(e.target.name, e.target.value)}
                    value={values["sand_type"]}
                    fullWidth
                    required
                  >
                    {sandType.map((sandType, ind) => (<MenuItem key={ind} value={sandType}>{sandType}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid >
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} className="GridItem">
                <FormControl fullWidth required>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    onChange={e => setValue(e.target.name, e.target.value)}
                    value={values["status"]}
                    fullWidth
                    required
                  >
                    {inventoryStatus.map((inventoryStatus, ind) => <MenuItem key={ind} value={inventoryStatus}>{inventoryStatus}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid >
              {makeField({ id: "quantity", label: "Quantity (lbs)", value: commas(values["quantity"]), onChange: e => setValue(e.target.id, commas(e.target.value)) })}
              {makeField({ id: "source_location", label: "Source Location" })}
              <Grid item xs={12} sm={12} md={12} style={{ marginTop: 10 }} className="GridItem">
                <FormControl fullWidth required>
                  <InputLabel>Transloading Client</InputLabel>
                  <Select
                    name="transloading_client"
                    onChange={e => setValue(e.target.name, e.target.value)}
                    value={values["transloading_client"]}
                    fullWidth
                    required
                  >
                    {clientName.map((clientName, ind) => <MenuItem key={ind} value={clientName}>{clientName}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid >
            </Grid>
            <Button
              color="primary"
              type="button"
              onClick={handleForm}
              disabled={(new Date(values["start_time"]) - new Date(values["end_time"])) > 0}
            >
              Update Inventory Item
            </Button>
          </GridHeader>
        </Grid>
      </Dialog>
    </div >
  )
}

export default InventoryEdit
