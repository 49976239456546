import React, { useState, useEffect, useRef, useContext } from "react"
import { Switch, Route, useLocation, Redirect } from "react-router-dom"
import { CircularProgress } from "@material-ui/core"
import useStyles from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx"
import Navbar from "components/Navbar.jsx"
import Sidebar from "components/Sidebar.jsx"
import routes from "routes.js"
import { queries } from "queries"
import { useGqlDirect } from "helpers"
import { actionGenerators } from "reducer"
import { ReducerContext } from "App"

const Home = () => {
  const { dispatch, state: { reset } } = useContext(ReducerContext)
  const location = useLocation()
  const mainPanelRef = useRef(null)
  const { wrapper, mainPanel, content, container } = useStyles()
  const [mobileOpen, setMobileOpen] = useState(false)
  const toggle = () => setMobileOpen(!mobileOpen)
  useEffect(() => { if (mainPanelRef.current) mainPanelRef.current.scrollTop = 0 }, [location])

  const { data: constants } = useGqlDirect(queries.GET_ACTIVE_CONST)
  const { data: allUsers } = useGqlDirect(queries.GET_ALL_USERS)

  useEffect(() => {
    if (constants && allUsers) {
      dispatch(actionGenerators.init({ constants, allUsers, reset: false }))
    }
  }, [constants, allUsers, dispatch])

  return <>
    {reset ? <CircularProgress /> :
      <div className={wrapper}>
        <Sidebar
          routes={routes.filter(route => route.path !== "/unauthorized")}
          toggle={toggle}
          open={mobileOpen}
        />
        <div className={mainPanel} ref={mainPanelRef}>
          <Navbar toggle={toggle} />
          <div className={content}>
            <div className={container}>
              <Switch>
                {routes.map((route, key) => <Route exact={route.path === "/"} path={route.path} key={key}>{route.component}</Route>)}
                <Redirect from="/" to="/outbound" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    }</>
}

export default Home
