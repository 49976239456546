import React, { useState, useRef, useContext, useEffect, useMemo } from "react"
import { CSVReader } from "react-papaparse"
import { Dialog, DialogTitle, DialogActions, DialogContent, IconButton, CircularProgress } from "@material-ui/core"
import { Publish, ErrorOutline, Edit, SaveAlt } from '@material-ui/icons'
import InventoryEdit from "components/Inventory/InventoryEdit"
import Button from "components/common/Button"
import ErrorDialog from "components/common/ErrorDialog"
import ProgressBar from "components/common/ProgressBar"
import { commas, useDebounce, useRestApiPost, useLazyRestApiPost } from "helpers"
import MUIDataTable from "mui-datatables"
import { useInvTableImport } from "components/Inventory/InventoryTableImport"
import { ReducerContext } from "App"

const railCarMaxCapacity = 230000

const InventoryTable = () => {
  const { state: { currentTerminal } } = useContext(ReducerContext)
  const fileInput = useRef(null)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  const [specificInventoryData, setSpecificInventoryData] = useState(null)

  const initSqlParams = useMemo(() => ({
    search_text: null,
    page_number: 0,
    sort: { column: 'updated_at', direction: 'desc' },
    filter: {},
    num_per_page: 20,
    currentTerminal
  }), [currentTerminal])

  const [sqlParams, setSqlParams] = useState(initSqlParams)

  const { data: termInvData, loading: termInvLoading, error: termInvError } = useRestApiPost('api/terminv', initSqlParams)

  useEffect(() => {
    termInvData && setData(termInvData)
  }, [termInvData])

  const [getTermInv, { data: getTermInvData, loading: getTermInvLoading, error: getTermInvError }] = useLazyRestApiPost('api/terminv')

  useEffect(() => {
    getTermInvData && setData(getTermInvData)
  }, [getTermInvData])

  const getTermInvDebounced = useDebounce(getTermInv, 1000)
  const { handleReadCSV, csvErrors, importCount, setCsvErrors, setImportCount } = useInvTableImport(getTermInv, initSqlParams)

  const handleClose = () => setOpen(false)

  const handleClick = rowIndex => {
    setSpecificInventoryData(data[rowIndex])
    setOpen(true)
  }

  const columns = [
    { label: "Actions", name: "Edit", options: { filter: false, sort: false, empty: true, customBodyRenderLite: (_, rowIndex) => <IconButton onClick={() => handleClick(rowIndex)}><Edit /></IconButton> } },
    { label: "Inventory ID", name: "inventory_id", editable: "never" },
    { label: "Owner", name: "owner" },
    { label: "Filled %", name: "quantity", options: { customBodyRender: quantity => <ProgressBar row={{ quantity }} type="inventory" /> } },
    { label: "Storage Type", name: "storage_type" },
    { label: "Sand Type", name: "sand_type" },
    { label: "Status", name: "status" },
    { label: "Quantity (lbs)", name: "quantity", options: { customBodyRenderLite: (_, rowIndex) => commas(data[rowIndex].quantity) } },
    { label: "Source Location", name: "source_location" },
    { label: "Transloading Client", name: "transloading_client" }
  ]

  const col_names = columns.map(col => col.name)

  const options = {
    selectableRows: 'none',
    searchAlwaysOpen: true,
    print: false,
    viewColumns: false,
    filterType: 'textField',
    serverSide: true,
    page: sqlParams.page_number,
    count: data[0] ? data[0].total_rows : 0,
    rowsPerPage: sqlParams.num_per_page,
    rowsPerPageOptions: [20, 50, 100],
    customToolbar: () => <>{(getTermInvLoading || termInvLoading) && <CircularProgress />}</>,
    onChangePage: page_number => {
      setSqlParams({ ...sqlParams, page_number })
      getTermInv({ ...sqlParams, page_number })
    },
    onChangeRowsPerPage: num_per_page => {
      setSqlParams({ ...sqlParams, num_per_page })
      getTermInv({ ...sqlParams, num_per_page })
    },
    onColumnSortChange: (column, direction) => {
      setSqlParams({ ...sqlParams, sort: { column, direction }, page_number: 0 })
      getTermInv({ ...sqlParams, sort: { column, direction }, page_number: 0 })
    },
    onFilterChange: (_, filterList) => {
      setSqlParams({ ...sqlParams, filter: Object.fromEntries(filterList.flatMap((item, ind) => item[0] ? [[col_names[ind], item[0]]] : [])), page_number: 0 })
      getTermInvDebounced({ ...sqlParams, filter: Object.fromEntries(filterList.flatMap((item, ind) => item[0] ? [[col_names[ind], item[0]]] : [])), page_number: 0 })
    },
    onSearchChange: search_text => {
      setSqlParams({ ...sqlParams, search_text, page_number: 0 })
      getTermInvDebounced({ ...sqlParams, search_text, page_number: 0 })
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const cols = columns.filter(item => item.label !== 'Actions')
      const dataFormatted = data.map(item => {
        const [, inventory_id, owner, quantity, ...rest] = item.data
        return { ...item, data: [inventory_id, owner, `${(((quantity > 0 ? quantity : 0) / railCarMaxCapacity) * 100).toPrecision(3)}%`, ...rest] }
      })

      return buildHead(cols) + buildBody(dataFormatted)
    }
  }

  const components = { icons: { DownloadIcon: SaveAlt } }

  return (
    <>
      {(termInvError || getTermInvError) && <ErrorDialog error={"error"} />}
      <CSVReader onFileLoad={handleReadCSV} ref={fileInput}>
        {() => <Button onClick={e => fileInput.current && fileInput.current.open(e)} color="primary" style={{ margin: "15px" }}> <Publish />Import</Button>}
      </CSVReader>

      <MUIDataTable title={<CircularProgress />} {...{ data, columns, options, components }} />

      <Dialog open={csvErrors.length > 0}>
        <DialogTitle style={{ fill: '#f44336', color: "#f44336" }} ><ErrorOutline style={{ fontSize: "34px" }} /> An error has occured</DialogTitle>
        <DialogContent>
          {csvErrors.map(e => (
            <div xs={12} key={e.row}>
              <p>Errors on row {e.row}</p>
              <ul>{e.errors.map((err, ind) => <li key={ind}>{err}</li>)}</ul>
            </div>
          ))}
        </DialogContent>
        <DialogActions><Button onClick={() => setCsvErrors([])}>OK</Button></DialogActions>
      </Dialog>

      <Dialog open={importCount > 0}>
        <DialogTitle>{"Success"}</DialogTitle>
        <DialogContent>{importCount} inventory locations were updated or added.</DialogContent>
        <DialogActions><Button onClick={() => setImportCount(0)}>OK</Button></DialogActions>
      </Dialog>
      {open && <InventoryEdit {...{ open, handleClose, specificInventoryData, getTermInv, sqlParams }} />}
    </>
  )
}

export default InventoryTable
