import React, { useContext, useState } from "react"
import axios from "axios"
import MaterialTable from "material-table"
import { IconButton } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import Button from "components/common/Button"
import PatchedPagination from "components/common/PatchedPagination"
import SettingsTableModify from "components/Settings/SettingsTableModify"
import SettingsTableAdd from "components/Settings/SettingsTableAdd"
import { ReducerContext } from "App"

const SettingsTable = ({ category, categoryTitle, getAllConst }) => {
  const { state: { user: { id: dbUserId }, allConstCategories } } = useContext(ReducerContext)
  const constCat = allConstCategories[category].map(row => ({ ...row, enabled: row.archived_at ? "disabled" : "enabled" }))
  const [specificData, setSpecificData] = useState(null)
  const [editOpen, setEditOpen] = useState(false)
  const [newOpen, setNewOpen] = useState(false)

  const handleEditClose = () => setEditOpen(false)
  const handleNewClose = () => setNewOpen(false)

  const handleEditClick = (data) => {
    setSpecificData(data)
    setEditOpen(true)
  }

  const columns = [
    { title: categoryTitle, field: "type" },
    { title: "Enabled", field: "enabled" }
  ]

  const update = async updates => {
    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
        {
          array: updates,
          dbTable: "constants",
          dbEvent: "update",
          dbUnique: "id",
          dbUserId
        }
      )

      getAllConst()
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const insert = async item => {
    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/insert`,
        {
          item,
          dbTable: "constants",
          dbEvent: "insert",
          dbUserId
        }
      )

      getAllConst()
    } catch (error) {
      console.log('error: ', error);
    }
  }

  return (
    <>
      <MaterialTable
        title=""
        columns={columns}
        data={constCat.sort(({ type: a }, { type: b }) => a.toUpperCase() < b.toUpperCase() ? -1 : a.toUpperCase() > b.toUpperCase() ? 1 : 0)}
        options={{ pageSize: 5, pageSizeOptions: [10], exportButton: true, headerStyle: { fontSize: "14px" }, filtering: true }}
        actions={[{ icon: '', onClick: () => { } }]} //if removed Action component below won't render
        components={{
          Action: props => <>{
            <IconButton onClick={() => handleEditClick(props.data)}>
              <Edit />
            </IconButton>}</>,
          Pagination: PatchedPagination
        }}
      />
      <Button color="primary" type="button" onClick={() => setNewOpen(true)}>{`Add New ${categoryTitle}`}</Button>
      {(editOpen && specificData) && <SettingsTableModify
        open={editOpen}
        handleClose={handleEditClose}
        update={update}
        specificData={specificData}
        categoryTitle={categoryTitle}
      />}
      {newOpen && <SettingsTableAdd
        open={newOpen}
        handleClose={handleNewClose}
        insert={insert}
        user_id={dbUserId}
        category={category}
        categoryTitle={categoryTitle}
      />}
    </>
  )
}

export default SettingsTable
