import React, { useState, useContext } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { ReducerContext } from 'App'
import axios from "axios"

const BOLCancel = ({
  bolId,
  bolStatusCancelled,
  bolStatus,
  getBOLs,
  sqlParams,
  handleClose: parentHandleClose
}) => {
  const { state: { user: { id: dbUserId }, currentTerminal } } = useContext(ReducerContext)
  const [open, setOpen] = useState(false)

  const buttonStyle = {
    backgroundColor: "#ef5350",
    color: "white",
    padding: "9px 20px",
    margin: "0 20px"
  }
  const hiddenButton = {
    display: "none"
  }

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)


  const handleCancelation = async () => {
    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
        {
          array: [{ bolId, status: "cancelled", last_edited_by: dbUserId }],
          dbTable: "Bills_Of_Lading",
          dbEvent: "update",
          dbUnique: "bolId",
          dbUserId
        }
      )
      getBOLs({ ...sqlParams, page_number: 0, currentTerminal })
      parentHandleClose()
    } catch (error) {
      console.log('error: ', error);
    }
  }


  return (
    <span>
      <Button style={(bolStatusCancelled) ? hiddenButton : buttonStyle} type="button" value="cancel" onClick={handleClickOpen} disabled={bolStatus === "cancelled"}>Cancel BOL</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"You Are Attempting to Cancel a BOL"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Cancelling this BOL will not revert inventory or processed Purchase Order amounts. You will need to manually edit any associated inventory locations and Purchase Orders to reflect these changes. THESE CHANGES CANNOT BE UNDONE. ARE YOU SURE YOU WISH TO CANCEL THIS BOL?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" style={{ backgroundColor: "rgb(67, 160, 71)", color: "white", padding: "9px 20px", marginLeft: "30px" }}>
            NO! Don't Change Anything!
          </Button>
          <Button onClick={handleCancelation} color="primary" style={{ backgroundColor: "#ef5350", color: "white", padding: "9px 20px", marginLeft: "30px" }} autoFocus>
            Cancel BOL
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}

export default BOLCancel
