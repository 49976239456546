import BOLs from "components/BOL/BOLs"
import BOLCreate from "components/BOL/BOLCreate"
import Reports from "components/Reports/Reports"
import PurchaseOrders from "components/PurchaseOrder/PurchaseOrders"
import PurchaseOrderCreate from "components/PurchaseOrder/PurchaseOrderCreate"
import Inventory from "components/Inventory/Inventory"
import InventoryInteractions from "components/Inventory/InventoryInteractions"
import Settings from "components/Settings/Settings"
import Unauthorized from "components/common/Unauthorized"

const dashboardRoutes = [
  {
    path: "/bol-history",
    name: "BOL History",
    icon: "list",
    component: <BOLs active={false} />
  },
  {
    path: "/inbound",
    name: "Create Inbound BOL",
    icon: "assignment",
    component: <BOLCreate />
  },
  {
    path: "/outbound",
    name: "BOL Outbound",
    icon: "local_shipping",
    component: <BOLs active={true} />
  },
  {
    path: "/purchase-orders-list",
    name: "Purchase Orders",
    icon: "list",
    component: <PurchaseOrders />
  },
  {
    path: "/create-purchase-order",
    name: "Create Purchase Order",
    icon: "create_new_folder",
    component: <PurchaseOrderCreate />
  },
  {
    path: "/view-inventory",
    name: "Inventory Locations",
    icon: "list",
    component: <Inventory />
  },
  {
    path: "/inventory-interactions",
    name: "Inventory Interactions",
    icon: "list",
    component: <InventoryInteractions />
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "description",
    component: <Reports />
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "settings",
    component: <Settings />
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    icon: "unauthorized",
    component: <Unauthorized />
  },
]

export default dashboardRoutes;
