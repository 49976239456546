import React, { useState, useContext } from "react"
import axios from "axios"
import MaterialTable from "material-table"
import { Grid, IconButton, Dialog, TextField, CircularProgress, Checkbox, FormControlLabel, InputLabel, Select, FormControl, MenuItem } from "@material-ui/core"
import { AddBox, Edit, Delete } from "@material-ui/icons"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Card, CardHeader, CardBody } from "components/common/Card"
import Button from "components/common/Button"
import PatchedPagination from "components/common/PatchedPagination"
import BOLCheckoutModify from "components/BOL/BOLCheckoutModify"
import { commas, noCommas, useForm } from "helpers"
import { ReducerContext } from "App"

const BOLCheckout = ({
  invInter,
  inventoryData,
  activePOsData,
  getBOLs,
  sqlParams,
  loadInvInter,
  specificInvInterLoading,
  loadInventory,
  open,
  handleClose,
  printCheckboxHandler,
  specificPoData,
  specificBolData: {
    bolId,
    po_id,
    driver_name,
    truck_number,
    trailer_number,
    carrier,
    tare_weight,
    notes,
    sand_type,
    buyer,
    transloading_client,
    job_name,
    external_reference_number
  }
}) => {
  const { state: { currentTerminal, user: { id: dbUserId, weighmaster_license } } } = useContext(ReducerContext)
  const [grossWeightInput, setGrossWeightInput] = useState("")
  const [inventory_id, setInventory_id] = useState(null)
  const [printCheckbox, setPrintCheckbox] = useState(true)
  const [selectedPO, setSelectedPO] = useState(specificPoData)
  const [specificInvData, setSpecificInvData] = useState(null)
  const [invOpen, setInvOpen] = useState(false)
  const [invMod, setInvMod] = useState(null)

  const handleInvClose = () => setInvOpen(false)
  const handleClick = (invData, type) => {
    setSpecificInvData(invData)
    setInvOpen(true)
    setInvMod(type)
  }

  const bolInit = {
    po_id,
    driver_name,
    truck_number,
    trailer_number,
    carrier,
    tare_weight,
    notes
  }

  const poInit = {
    sand_type,
    buyer,
    transloading_client,
    job_name,
    external_reference_number
  }
  const { setValue: setBOLValue, values: bolValues } = useForm(bolInit);
  const { setValue: setPOValue, values: poValues } = useForm(poInit);

  const shippingWeight = ({ tableData: { id }, gross_weight }, tare_weight, inter) => commas(id > 0 ?
    inter[id].gross_weight - inter[id - 1].gross_weight
    :
    gross_weight - tare_weight)

  const columns = [
    { title: "Tare Weight", editable: "never", render: () => commas(bolValues["tare_weight"]) },
    { title: "Net Weight", editable: "never", render: row => commas(row.gross_weight - noCommas(bolValues["tare_weight"])) },
    { title: "Shipping Weight", editable: "never", render: row => shippingWeight(row, noCommas(bolValues["tare_weight"]), invInter) },
    { title: "Gross Weight", field: "gross_weight", render: row => specificInvInterLoading ? <CircularProgress /> : commas(row.gross_weight) },
    { title: "Time Stamp", field: "created_at", hidden: true },
    {
      title: "Inventory ID", field: "inventory_id",
      ...((inventoryData.length === 0) && { render: () => <CircularProgress /> }),
      ...((inventoryData.length !== 0) && { render: rowdata => specificInvInterLoading ? <CircularProgress /> : rowdata.inventory_id })
    }
  ]

  const handleInvSubmit = async e => {
    e.preventDefault()
    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/insert`,
        {
          item: { gross_weight: noCommas(grossWeightInput), inventory_id, bill_of_lading_id: bolId, terminal: currentTerminal },
          dbTable: "Inventory_Interactions",
          dbEvent: "insert",
          dbUserId
        }
      )
      loadInvInter({ bol_id: bolId })
    } catch (error) {
      console.log('error: ', error)
    }
    setGrossWeightInput("")
  }

  const handleInvEdit = async (variables, action, isDirty) => {
    if (action === "edit" && isDirty) {
      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
          {
            array: [variables],
            dbTable: "Inventory_Interactions",
            dbEvent: "update",
            dbUnique: "id",
            dbUserId
          }
        )
        loadInvInter({ bol_id: bolId })
      } catch (error) {
        console.log('error: ', error);
      }
    }

    if (action === "delete") {
      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
          {
            array: [{ id: variables.id, archived_at: new Date().toISOString() }],
            dbTable: "Inventory_Interactions",
            dbEvent: "update",
            dbUnique: "id",
            dbUserId
          }
        )
        loadInvInter({ bol_id: bolId })
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }

  const updateAllTerminalInventory = async () => {
    const distinctLocations = Array.from(new Set(invInter.map(i => i.inventory_id)))
    const shippings = invInter.reduce((acc, cur, ind, arr) => {
      const shippingWeight = ind > 0 ? cur.gross_weight - arr[ind - 1].gross_weight : cur.gross_weight - (noCommas(bolValues["tare_weight"]) || tare_weight)
      return [...acc, { shippingWeight, inventory_id: cur.inventory_id }]
    }, [])

    const updated_at = new Date().toISOString()

    const updates = distinctLocations.map(location_id => {
      const totalShipped = shippings.reduce((acc, cur) => cur.inventory_id === location_id ? cur.shippingWeight + acc : acc, 0)
      const [location] = inventoryData.filter(item => item.inventory_id === location_id)
      return { inventory_id: location.inventory_id, quantity: location.quantity - totalShipped, updated_at }
    })

    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
        {
          array: updates,
          dbTable: "Terminal_Inventory",
          dbEvent: "update",
          dbUnique: "inventory_id",
          dbUserId
        }
      )
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const handlePOSelect = (e) => {
    const [selectedPO] = activePOsData.filter(po => po.id === e.target.value)
    const { transloading_client, sand_type, id: po_id } = selectedPO
    setSelectedPO(selectedPO)
    setInventory_id(null)
    Object.entries(selectedPO).map(([key, val]) => setPOValue(key, val))
    setBOLValue("po_id", po_id)
    loadInventory({ transloading_client, sand_type })
  }

  // TODO: combine and convert all this to transactions
  const handleForm = async type => {
    const printData = await saveBOL(type === "submit" ? "completed" : "started")
    if (type === "submit") {

      await updateAllTerminalInventory();

      try {
        printCheckboxHandler({ printCheckbox, printData: { ...printData, ...selectedPO } })
        handleClose()
      } catch (error) {
        console.log('error: ', error);
        saveBOL("started")
      }
    }

    handleClose()
  }

  const saveBOL = async status => {
    const { tare_weight, notes, ...rest } = bolValues

    const update = {
      ...rest,
      bolId,
      tare_weight: typeof tare_weight === "number" ? tare_weight : noCommas(tare_weight),
      gross_weight: invInter.length ? Number(invInter[invInter.length - 1].gross_weight) : 0,
      status,
      user: String(dbUserId),
      terminal: currentTerminal,
      weighmaster_license,
      processed_at: new Date().toISOString(),
      notes: notes === "" ? null : notes
    }

    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
        {
          array: [update],
          dbTable: "Bills_Of_Lading",
          dbEvent: "update",
          dbUnique: "bolId",
          dbUserId
        }
      )
      getBOLs({ ...sqlParams, page_number: 0, currentTerminal })
      return update
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const textFields = [
    { disabled: true, width: 6, id: "sand_type", label: "Product", form: "po" },
    { disabled: true, width: 6, id: "buyer", label: "Buyer", form: "po" },
    { disabled: true, width: 6, id: "transloading_client", label: "Transloading Client", form: "po" },
    { disabled: true, width: 6, id: "job_name", label: "Job Name", form: "po" },
    { disabled: true, width: 6, id: "external_reference_number", label: "External Reference Number", form: "po" },
    { disabled: false, width: 12, id: "driver_name", label: "Driver's Full Name", form: "bol" },
    { disabled: false, width: 6, id: "truck_number", label: "Truck Number", form: "bol" },
    { disabled: false, width: 6, id: "trailer_number", label: "Trailer Number", form: "bol" },
    { disabled: false, width: 12, id: "carrier", label: "Carrier Name", form: "bol" },
    {
      disabled: invInter.length !== 0,
      value: commas(bolValues["tare_weight"]),
      width: 12,
      id: "tare_weight",
      label: "Tare Weight",
      onChange: e => setBOLValue(e.target.id, commas(e.target.value)),
      error: (noCommas(bolValues["tare_weight"]) < 15000 || noCommas(bolValues["tare_weight"]) > 80000) || !bolValues["tare_weight"],
      helperText: noCommas(bolValues["tare_weight"]) < 15000 ? "Tare Weight must be greater than 15,000 lbs" : noCommas(bolValues["tare_weight"]) > 80000 ? "Tare Weight must be less than 80,000 lbs" : ""
    },
    { disabled: false, width: 12, id: "notes", label: "Notes", form: "bol" }
  ]

  const makeField = ({ id, label, width, disabled, onChange, error, helperText, value, form }) => {
    return <Grid item xs={12} sm={width} md={width} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        value={value ? value : form === "bol" ? bolValues[id] || "" : poValues[id] || ""}
        onChange={onChange ? onChange : form === "bol" ? e => setBOLValue(e.target.id, e.target.value) : e => setPOValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "notes"}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
    </Grid >
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="xl"
      >
        <Grid container spacing={1} className="addMarginLeft">
          <Grid item sm={12} md={6} >
            <Card>
              <CardHeader color="primary">
                <h4 className="cardTitleWhite">Add New Gross Weight to BOL #{String(bolId)}</h4>
                <p className="cardCategoryWhite">Select an Inventory Location &amp; enter the gross weight. Then click add (+).</p>
              </CardHeader>
              <CardBody>
                <form onSubmit={e => handleInvSubmit(e)}>
                  <Grid container className="GridContainer">
                    <Grid item xs={11} sm={5} md={5} className="GridItem">
                      <Autocomplete
                        id="inventory-location"
                        options={inventoryData.map(item => item.inventory_id)}
                        onChange={(_, inv_id) => setInventory_id(inv_id)}
                        value={inventory_id}
                        fullWidth
                        renderInput={params => <TextField {...params} label="Inventory Location" fullWidth required />}
                      />
                    </Grid>
                    <Grid item xs={11} sm={5} md={5} className="GridItem">
                      <TextField
                        label="Gross Weight"
                        required
                        fullWidth
                        value={grossWeightInput}
                        error={noCommas(grossWeightInput) <= noCommas(bolValues["tare_weight"]) || (noCommas(grossWeightInput) > 150000) || noCommas(grossWeightInput) <= invInter[invInter.length - 1]?.gross_weight}
                        helperText={noCommas(grossWeightInput) <= noCommas(bolValues["tare_weight"]) || noCommas(grossWeightInput) <= invInter[invInter.length - 1]?.gross_weight ? "Gross weight must be greater than tare weight or most recent gross weight" : noCommas(grossWeightInput) > 150000 ? "Gross weight is too high." : ""}
                        onChange={e => setGrossWeightInput(commas(e.target.value))}
                      />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} className="GridItem" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <IconButton
                        aria-label="add gross weight"
                        size="medium"
                        type="submit"
                        disabled={noCommas(grossWeightInput) <= noCommas(bolValues["tare_weight"]) || noCommas(grossWeightInput) > 150000 || noCommas(grossWeightInput) <= invInter[invInter.length - 1]?.gross_weight}
                      >
                        <AddBox fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
            </Card>

            {specificInvInterLoading ? <CircularProgress /> : <MaterialTable
              title="Finalize Bill of Lading"
              columns={columns}
              data={invInter}
              options={{ search: false, headerStyle: { backgroundColor: "#43a047", color: "#FFF", textAlign: "left", marginTop: "30px", marginLeft: "8px" } }}
              actions={[{ icon: '', onClick: () => { } }]} //if removed Action component below won't render
              components={{
                Action: props => <>{
                  <><IconButton onClick={() => handleClick(props.data, "edit")}>
                    <Edit />
                  </IconButton>
                    <IconButton onClick={() => handleClick(props.data, "delete")}>
                      <Delete />
                    </IconButton></>}</>,
                Pagination: PatchedPagination
              }}
            />}
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container className="GridContainer">
              <Grid item xs={12} className="GridItem">
                <Card>
                  <CardHeader color="primary">
                    <h4 className="cardTitleWhite">Bill of Lading ID #{bolId}</h4>
                    <p className="cardCategoryWhite">Please confirm these details are correct before finalizing this BOL.</p>
                  </CardHeader>
                  <CardBody>
                    <Grid container className="GridContainer">
                      <Grid item xs={12} sm={6} md={6} className="GridItem">
                        <FormControl fullWidth required style={{ margin: "16px 0 8px" }}>
                          <InputLabel>Purchase Order Number</InputLabel>
                          <Select
                            name="purchase_order"
                            onChange={handlePOSelect}
                            fullWidth
                            value={bolValues["po_id"]}
                            required
                            disabled={invInter.length !== 0}
                          >
                            {activePOsData.map(PurchaseOrder => (
                              <MenuItem key={PurchaseOrder.id} value={PurchaseOrder.id}>
                                {PurchaseOrder.purchase_order_number}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {textFields.map(field => makeField(field))}
                      <Grid item className="GridItem">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={printCheckbox}
                              color="primary"
                              onChange={e => setPrintCheckbox(e.target.checked)}
                              name="printCheckbox"
                            />
                          }
                          label="Print BOLs"
                        />
                      </Grid>
                    </Grid>
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => handleForm("submit")}
                      disabled={(invInter.length < 1) || (noCommas(bolValues["tare_weight"]) < 15000 || noCommas(bolValues["tare_weight"]) > 80000 || !bolValues["tare_weight"])}
                    >
                      {invInter.length < 1 ? "No Inventory Interactions Found" : "Process BOL"}
                    </Button>
                    <Button
                      color="white"
                      type="button"
                      onClick={() => handleForm("save")}
                      disabled={noCommas(bolValues["tare_weight"]) < 15000 || noCommas(bolValues["tare_weight"]) > 80000 || !bolValues["tare_weight"]}
                    >
                      Save
                    </Button>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      {invOpen && <BOLCheckoutModify
        open={invOpen}
        invMod={invMod}
        handleClose={handleInvClose}
        invInter={invInter}
        inventoryData={inventoryData.map(item => item.inventory_id)}
        tare_weight={bolValues["tare_weight"]}
        specificInvData={specificInvData}
        shippingWeight={shippingWeight}
        handleInvEdit={handleInvEdit}
      />}
    </>
  );
}

export default BOLCheckout