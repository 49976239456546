import React, { useState } from "react"
import { TextField, Grid, Dialog } from "@material-ui/core"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"

const SettingsTableAdd = ({ open, handleClose, insert, user_id, category, categoryTitle }) => {
  useStyles()
  const [input, setInput] = useState("")

  const handleSubmit = () => {
    insert({ category, type: input, user_id })
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <Grid container className="GridContainer">
          <GridHeader h4={`Add New ${categoryTitle}`}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} className="GridItem">
                <TextField
                  id={"type"}
                  label={categoryTitle}
                  value={input}
                  onChange={e => setInput(e.target.value)}
                  margin="normal"
                  required
                />
              </Grid >
            </Grid>
            <Button color="primary" type="button" onClick={handleSubmit}>Add</Button>
          </GridHeader>
        </Grid>
      </Dialog>
    </div>
  )
}

export default SettingsTableAdd