import React, { useContext } from "react"
import MaterialTable from "material-table"
import { Grid, Dialog, TextField, Box } from "@material-ui/core"
import { Card, CardHeader, CardBody } from "components/common/Card"
import Button from "components/common/Button"
import PatchedPagination from "components/common/PatchedPagination"
import BOLCancel from "components/BOL/BOLCancel"
import { commas, useForm } from "helpers"
import { ReducerContext } from "App"
import axios from "axios"

const BOLEdit = ({
  invInter,
  getBOLs,
  sqlParams,
  open,
  handleClose,
  specificBolData: {
    bolId,
    driver_name,
    truck_number,
    trailer_number,
    carrier,
    tare_weight,
    notes,
    status,
    purchase_order_number,
    sand_type,
    buyer,
    transloading_client,
    job_name,
    external_reference_number
  }
}) => {
  const { state: { user: { id: dbUserId }, currentTerminal } } = useContext(ReducerContext)

  const init = {
    purchase_order_number,
    sand_type,
    buyer,
    transloading_client,
    job_name,
    external_reference_number,
    driver_name,
    truck_number,
    trailer_number,
    carrier,
    tare_weight,
    notes,
    status
  }

  const { setValue, values, isDirty } = useForm(init);

  const shippingWeight = row => row.tableData.id > 0 ?
    invInter[row.tableData.id].gross_weight - invInter[row.tableData.id - 1].gross_weight
    :
    row.gross_weight - tare_weight

  const columns = [
    { title: "Tare Weight", render: () => commas(tare_weight) },
    { title: "Net Weight", render: row => commas(row.gross_weight - tare_weight) },
    { title: "Shipping Weight", render: row => commas(shippingWeight(row)) },
    { title: "Gross Weight", render: row => commas(row.gross_weight) },
    { title: "Inventory ID", field: "inventory_id" }
  ]

  const handleForm = async (_e, revert) => {
    if (isDirty || revert) {
      const { buyer, sand_type, transloading_client, external_reference_number, job_name, tare_weight, purchase_order_number, notes, ...rest } = values

      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
          {
            array: [{ ...rest, bolId, last_edited_by: dbUserId, status: "completed", notes: notes === "" ? null : notes }],
            dbTable: "Bills_Of_Lading",
            dbEvent: "update",
            dbUnique: "bolId",
            dbUserId
          }
        )
        getBOLs({ ...sqlParams, page_number: 0, currentTerminal })
      } catch (error) {
        console.log('error: ', error);
      }
    }

    handleClose()
  }

  const textFields = [
    { disabled: true, width: 6, id: "purchase_order_number", label: "Purchase Order Number" },
    { disabled: true, width: 6, id: "sand_type", label: "Product" },
    { disabled: true, width: 6, id: "buyer", label: "Buyer" },
    { disabled: true, width: 6, id: "transloading_client", label: "Transloading Client" },
    { disabled: true, width: 6, id: "job_name", label: "Job Name" },
    { disabled: true, width: 6, id: "external_reference_number", label: "External Reference Number" },
    { disabled: false, width: 12, id: "driver_name", label: "Driver's Full Name" },
    { disabled: false, width: 6, id: "truck_number", label: "Truck Number" },
    { disabled: false, width: 6, id: "trailer_number", label: "Trailer Number" },
    { disabled: false, width: 12, id: "carrier", label: "Carrier Name" },
    { disabled: true, width: 12, id: "tare_weight", label: "Tare Weight" },
    { disabled: false, width: 12, id: "notes", label: "Notes" }
  ]

  const makeField = ({ id, label, width, disabled, onChange, error, helperText, value }) => {
    return <Grid item xs={12} sm={width} md={width} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        value={value ? value : values[id] || ""}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "notes"}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
    </Grid >
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="xl"
      >
        <Grid container spacing={1} className="addMarginLeft">
          <Grid item sm={12} md={6} >
            <MaterialTable
              title="Completed Bill of Lading Weights"
              columns={columns}
              data={invInter}
              options={{ search: false, headerStyle: { backgroundColor: "#43a047", color: "#FFF", textAlign: "left", marginTop: "30px", marginLeft: "8px" } }}
              components={{ Pagination: PatchedPagination }}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Grid container className="GridContainer">
              <Grid item xs={12} className="GridItem">
                <Card>
                  <CardHeader color="primary">
                    <h4 className="cardTitleWhite">Bill of Lading ID #{bolId}</h4>
                    <p className="cardCategoryWhite">Please confirm these details are correct before finalizing this BOL.</p>
                  </CardHeader>
                  <CardBody>
                    <Grid container className="GridContainer">
                      {textFields.map(field => makeField(field))}
                    </Grid>
                    <Box display="flex" justifyContent="center" style={{ paddingTop: "10px" }}>
                      <BOLCancel bolId={bolId} bolStatusCancelled={status === "cancelled"} getBOLs={getBOLs} sqlParams={sqlParams} handleClose={handleClose} />
                      {status === "cancelled" ?
                        <Button
                          color="white"
                          type="button"
                          onClick={e => handleForm(e, "revert")}
                          style={{ backgroundColor: "#ef5350", color: "white", padding: "9px 20px", margin: "0 20px" }}
                        >
                          Revert
                        </Button>
                        :
                        <Button
                          color="white"
                          type="button"
                          onClick={handleForm}
                          style={{ padding: "9px 20px", margin: "0 20px" }}
                        >
                          Save
                        </Button>}
                    </Box>
                  </CardBody >
                </Card >
              </Grid >
            </Grid >
          </Grid >
        </Grid >
      </Dialog >
    </div >
  );
}

export default BOLEdit