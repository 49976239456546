import React, { useState, useEffect, useContext, useRef } from "react"
import { useHistory } from "react-router-dom"
import DateFnsUtils from "@date-io/date-fns"
import { Grid, IconButton, Card, CardContent, CardHeader, Collapse } from "@material-ui/core"
import { Alert, AlertTitle } from '@material-ui/lab'
import { Close } from "@material-ui/icons"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import ErrorDialog from "components/common/ErrorDialog"
import GridHeader from "components/common/GridHeader"
import { queries } from "queries"
import { createDateString, CSVButton, useLazyGqlDirect } from "helpers"
import { ReducerContext } from "App"

const Reports = () => {
  const { state: { user: { permissions } } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])

  const [loadBols, { loading: bolsLoading, error: bolsError }] = useLazyGqlDirect(queries.GET_BOLS_RANGE)
  const [loadBolsWInvInter, { loading: bolsWInvInterLoading, error: bolsWInvInterError }] = useLazyGqlDirect(queries.GET_BOLS_RANGE_W_INV_INTER)

  const csvBtn = useRef()
  const [start_date, setStart_date] = useState(null)
  const [end_date, setEnd_date] = useState(null)
  const [CSVData, setCSVData] = useState(null)
  const [open, setOpen] = useState(false)

  const handleDateChange = (date, type) => {
    setOpen(false);
    (date instanceof Date) && type === "start" ? setStart_date(new Date(date.setHours(0, 0, 0, 0))) : setEnd_date(new Date(date.setHours(23, 59, 59, 59)))
  }

  return <Grid container className="GridContainer">
    <GridHeader h4="Export Reports" p="Date Range picker and export functionality for generating CSV files that can be handled &amp; delivered offline.">
      {(bolsError || bolsWInvInterError) ? <ErrorDialog /> :
        <Grid container className="GridContainer">
          <Grid item xs={12} sm={12} md={12} className="GridItem">
            <Grid container className="GridContainer">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={6} md={6} className="GridItem">
                  <KeyboardDatePicker
                    margin="normal"
                    id="start-date-picker-dialog"
                    required
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={start_date}
                    onChange={e => handleDateChange(e, "start")}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    autoOk={true}
                    disableFuture
                    fullWidth
                    className="getBold"
                  />
                </Grid>
                <Grid item xs={6} md={6} className="GridItem">
                  <KeyboardDatePicker
                    margin="normal"
                    id="end-date-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={end_date}
                    onChange={e => handleDateChange(e, "end")}
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    autoOk
                    disableFuture
                    fullWidth
                    required
                    className="getBold"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className="GridItem" style={{ marginTop: "20px" }}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Card variant="outlined">
                <CardHeader
                  subheader="Export CSV Reports"
                />
                <CardContent>
                  <CSVButton
                    label="BOLs"
                    ref={csvBtn}
                    data={CSVData}
                    loading={bolsLoading}
                    disabled={!start_date || !end_date}
                    filename={`BOLsFrom${start_date}-${end_date}.csv`.replace(/ /g, "_")}
                    fetchCSVData={async () => {
                      try {
                        const bolsDataRaw = await loadBols({ start_date, end_date })
                        const newBolsArray = bolsDataRaw.bols.map(bol => {
                          const { created_at, gross_weight, tare_weight, processed_at, bolId, Purchase_Orders: { purchase_order_number: purchase_order }, Purchase_Orders, ...rest } = bol
                          return { ...rest, created_at: createDateString(created_at), processed_at: createDateString(processed_at), net_weight: gross_weight - tare_weight, bol_number: bolId, purchase_order }
                        })
                        if (newBolsArray.length < 1) { setOpen(true) }
                        else {
                          setCSVData(newBolsArray)
                          await csvBtn.current.link.click()
                          setTimeout(() => setCSVData(null), 500)
                        }
                      } catch (error) {
                        console.log('error: ', error);
                        setTimeout(() => setCSVData(null), 500)
                      }
                    }}
                  />
                  <CSVButton
                    label="BOLs w/ Inventory Interactions"
                    ref={csvBtn}
                    data={CSVData}
                    loading={bolsWInvInterLoading}
                    disabled={!start_date || !end_date}
                    filename={`BOLsFrom${start_date}-${end_date}.csv`.replace(/ /g, "_")}
                    fetchCSVData={async () => {
                      try {
                        const bolsDataRaw = await loadBolsWInvInter({ start_date, end_date })
                        const newBolsArray = bolsDataRaw.bols.flatMap(bol => {
                          const { created_at, gross_weight, tare_weight, processed_at, bolId, Purchase_Orders, Inventory_Interactions: inter, ...rest } = bol
                          const { purchase_order_number: purchase_order, ...po_rest } = Purchase_Orders
                          return inter.map(({ gross_weight, inventory_id }, ind) => ({
                            ...rest,
                            created_at: createDateString(created_at),
                            processed_at: createDateString(processed_at),
                            net_weight: ind > 0 ? inter[ind].gross_weight - inter[ind - 1].gross_weight : gross_weight - tare_weight,
                            tare_weight,
                            gross_weight,
                            inventory_id,
                            bol_number: bolId,
                            purchase_order,
                            ...po_rest
                          }))
                        })

                        if (newBolsArray.length < 1) { setOpen(true) }
                        else {
                          setCSVData(newBolsArray)
                          await csvBtn.current.link.click()
                          setTimeout(() => setCSVData(null), 500)
                        }
                      } catch (error) {
                        console.log('error: ', error);
                        setTimeout(() => setCSVData(null), 500)
                      }
                    }}
                  />
                  <Collapse in={open}>
                    <Alert severity="error" action={<IconButton onClick={() => setOpen(false)}><Close fontSize="inherit" /></IconButton>}>
                      <AlertTitle>Error</AlertTitle>
                      No data to export on current dates. — <strong>Please select new dates.</strong>
                    </Alert>
                  </Collapse>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>}
    </GridHeader>
  </Grid>
}

export default Reports
