import React, { useContext, useState } from "react"
import MaterialTable from "material-table"
import { IconButton } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import Button from "components/common/Button"
import PatchedPagination from "components/common/PatchedPagination"
import UsersModify from "components/Settings/UsersModify"
import UsersAdd from "components/Settings/UsersAdd"
import { ReducerContext } from "App"

const Users = ({ getAllUsers }) => {
  const { state: { allUsers } } = useContext(ReducerContext)

  const users = allUsers.map(row => ({ ...row, enabled: row.archived_at ? "disabled" : "enabled" }))
  const [specificData, setSpecificData] = useState(null)
  const [editOpen, setEditOpen] = useState(false)
  const [newOpen, setNewOpen] = useState(false)

  const handleEditClose = () => setEditOpen(false)
  const handleNewClose = () => setNewOpen(false)

  const handleEditClick = (data) => {
    setSpecificData(data)
    setEditOpen(true)
  }

  const columns = [
    { title: "Email", field: "email" },
    { title: "First Name", field: "first_name" },
    { title: "Last Name", field: "last_name" },
    { title: "Weighmaster License", field: "weighmaster_license" },
    { title: "Permissions", render: row => row.permissions.length > 0 ? row.permissions[0].slice(0, 1).toUpperCase() + row.permissions[0].slice(1) : "None" },
    { title: "Terminals", render: row => row.terminals.join(', ') },
    { title: "Enabled", field: "enabled" }
  ]

  return (
    <>
      <MaterialTable
        title=""
        columns={columns}
        data={users.sort(({ last_name: a }, { last_name: b }) => a.toUpperCase() < b.toUpperCase() ? -1 : a.toUpperCase() > b.toUpperCase() ? 1 : 0)}
        options={{ pageSize: 5, pageSizeOptions: [10], exportButton: true, headerStyle: { fontSize: "14px" }, filtering: true }}
        actions={[{ icon: '', onClick: () => { } }]} //if removed Action component below won't render
        components={{
          Action: props => <>{
            <IconButton onClick={() => handleEditClick(props.data)}>
              <Edit />
            </IconButton>}</>,
          Pagination: PatchedPagination
        }}
      />
      <Button color="primary" type="button" onClick={() => setNewOpen(true)}>{`Add New User`}</Button>
      {(editOpen && specificData) && <UsersModify
        open={editOpen}
        handleClose={handleEditClose}
        specificData={specificData}
        getAllUsers={getAllUsers}
      />}
      {newOpen && <UsersAdd
        open={newOpen}
        handleClose={handleNewClose}
        getAllUsers={getAllUsers}
      />}
    </>
  )
}

export default Users
