import React, { useContext } from "react"
import axios from "axios"
import { TextField, Grid } from "@material-ui/core"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import { actionGenerators } from "reducer"
import { ReducerContext } from "App"
import { useForm } from "helpers"

const WeighmasterLicense = ({ handleClose }) => {
  useStyles()
  const { dispatch, state: { user: { weighmaster_license = "", id: dbUserId }, user } } = useContext(ReducerContext)
  const { setValue, values, isDirty } = useForm({ weighmaster_license })

  const handleSubmit = async () => {
    if (isDirty) {
      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
          {
            array: [{ ...values, id: dbUserId, weighmaster_license: values["weighmaster_license"] === '' ? null : values["weighmaster_license"] }],
            dbTable: "users",
            dbEvent: "update",
            dbUnique: "id",
            dbUserId
          }
        )
        dispatch(actionGenerators.update({ user: { ...user, ...values } }))
      } catch (error) {
        console.log('error: ', error);
      }
    }

    handleClose()
  }

  return (
    <Grid container className="GridContainer">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className="GridItem">
          <TextField
            id={"weighmaster_license"}
            label={"Weighmaster License"}
            value={values["weighmaster_license"] ? values["weighmaster_license"] : ""}
            onChange={({ target: { id, value } }) => setValue(id, value)}
            margin="normal"
            fullWidth
          />
        </Grid >
      </Grid>
      <Button color="primary" type="button" onClick={() => handleSubmit()}>Update</Button>
    </Grid>
  )
}

export default WeighmasterLicense