import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  '@global': {
    '.cardCategoryWhite': {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    '.cardTitleWhite': {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    '.getBold': {
      fontWeight: "bold"
    },
    '.addMarginTop': {
      marginTop: "30px"
    },
    '.addMarginLeft': {
      marginLeft: "4px"
    },
    '.GridContainer': {
      margin: "0 -15px !important",
      width: "unset"
    },
    '.GridItem': {
      padding: "0 15px !important"
    },
    dataGridCell: {
      overFlow: "visible",
      whiteSpace: "normal"
    }
  }
})

export default useStyles