import React, { useContext } from "react"
import axios from "axios"
import { TextField, Grid, Dialog, Slider, Typography, FormControl, FormControlLabel, FormGroup, Checkbox } from "@material-ui/core"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"
import { useForm } from "helpers"
import { ReducerContext } from "App"

const UsersModify = ({ open, handleClose, getAllUsers }) => {
  const { state: { user: { id: dbUserId }, constsArrays: { terminals } } } = useContext(ReducerContext)
  useStyles()

  const init = {
    email: "",
    first_name: "",
    last_name: "",
    permissions: [],
    terminals: [],
    weighmaster_license: ""
  }
  const { setValue, values } = useForm(init)

  const handleSubmit = async e => {
    e.preventDefault()
    const { weighmaster_license, permissions, terminals, ...rest } = values

    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/insert`,
        {
          item: {
            ...rest,
            weighmaster_license: weighmaster_license === "" ? null : weighmaster_license,
            permissions: `{${permissions.map(cur => `"${cur}"`).join(',')}}`,
            terminals: `{${terminals.map(cur => `"${cur}"`).join(',')}}`
          },
          dbTable: "users",
          dbEvent: "insert",
          dbUserId
        }
      )

      getAllUsers()
      handleClose()
    } catch (error) {
      console.log('error: ', error);
      return error
    }
  }

  const marks = [
    { value: 0, label: 'User' },
    { value: 30, label: 'Manager' },
    { value: 60, label: 'Admin' }
  ]

  const setPermission = permission => {
    const [match] = marks.filter(mark => mark.label.toLowerCase() === permission)
    return match?.value ? match.value : 0
  }

  const textFields = [
    { id: "email", label: "Email" },
    { id: "first_name", label: "First Name" },
    { id: "last_name", label: "Last Name" },
    { id: "weighmaster_license", label: "Weighmaster License" }
  ]

  const makeField = ({ id, label, helperText, onChange }) => (
    <Grid item xs={12} sm={12} md={12} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        helperText={helperText}
        value={values[id]}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "weighmaster_license"}
      />
    </Grid >
  )

  const makeCheckbox = (terminal, ind) => (
    <FormControlLabel
      key={ind}
      label={terminal}
      control={
        <Checkbox
          name={terminal}
          color="primary"
          checked={values["terminals"].includes(terminal)}
          onChange={e => setValue("terminals", e.target.checked ? Array.from(new Set([...values["terminals"], terminal])) : values["terminals"].filter(term => term !== terminal))}
        />
      }
    />
  )

  const handleSlider = val => {
    if (val === 60) {
      setValue("permissions", ['admin', 'manager', 'user'])
    } else if (val === 30) {
      setValue("permissions", ['manager', 'user'])
    } else {
      setValue("permissions", ['user'])
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <Grid container className="GridContainer">
          <GridHeader h4={`Add User`}>
            <form onSubmit={e => handleSubmit(e)}>
              <Grid container>
                {textFields.map(field => makeField(field))}
                <Grid item xs={12} sm={12} md={12} className="GridItem">
                  <Typography variant="subtitle1" align="center" gutterBottom style={{ marginTop: "20px", marginBottom: "0px" }}>Permissions</Typography>
                  <Slider
                    value={setPermission(values["permissions"][0])}
                    valueLabelDisplay="off"
                    onChangeCommitted={(e, val) => handleSlider(val)}
                    step={30}
                    marks={marks}
                    min={0}
                    max={60}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="GridItem" >
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    {/* <Typography align="center" gutterBottom style={{ marginTop: "20px", marginBottom: "0px" }}>Terminals</Typography> */}
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: "20px", marginBottom: "0px" }}>Terminals</Typography>
                    <FormControl component="fieldset">
                      <FormGroup>
                        {terminals.map((term, ind) => makeCheckbox(term, ind))}
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Button color="primary" type="button" onClick={handleSubmit}>Add</Button> */}
              <Button color="primary" type="submit">Add</Button>
            </form>
          </GridHeader>
        </Grid>
      </Dialog >
    </>
  )
}

export default UsersModify