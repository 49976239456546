import React from "react"
import { TextField, Grid, Dialog } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import GridHeader from "components/common/GridHeader"
import { commas, noCommas, useForm } from "helpers"

const PurchaseOrderEdit = ({
  open,
  invMod,
  handleClose,
  invInter,
  inventoryData,
  tare_weight,
  specificInvData,
  shippingWeight,
  handleInvEdit
}) => {
  useStyles()

  const shipping_weight = shippingWeight(specificInvData, tare_weight, invInter)
  const net_weight = commas(specificInvData.gross_weight - tare_weight)
  const init = { ...specificInvData, tare_weight: commas(tare_weight), shipping_weight, net_weight }

  const { setValue, values, initVals, isDirty } = useForm(init);

  const handleSubmit = () => {
    const { id, bill_of_lading_id, gross_weight, inventory_id } = values
    handleInvEdit({ id, bill_of_lading_id, gross_weight: noCommas(gross_weight), inventory_id }, invMod, isDirty)
    handleClose()
  }

  const grossWeightFields = () => {
    const tare = noCommas(values["tare_weight"])
    const gross = noCommas(values["gross_weight"])
    const prevGross = invInter.reduce((acc, cur, ind, arr) => (cur.gross_weight === initVals["gross_weight"] ? (ind === 0 ? tare : arr[ind - 1].gross_weight) : acc), tare)
    const under = gross <= prevGross
    const over = gross > 150000

    return {
      error: under || over,
      helperText:
        under ?
          "Gross weight must be greater than tare weight or previous gross weight"
          :
          over ?
            "Gross weight is too high."
            :
            ""
    }
  }

  const textFields = [
    { id: "gross_weight", label: "Gross Weight", value: commas(values["gross_weight"]), ...grossWeightFields() },
    { id: "tare_weight", label: "Tare Weight", disabled: true },
    { id: "net_weight", label: "Net Weight", disabled: true },
    { id: "shipping_weight", label: "Shipping Weight", disabled: true }
  ]

  const makeField = ({ id, label, helperText, onChange, disabled, value, error }) => (
    <Grid item xs={12} sm={12} md={12} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        helperText={helperText}
        value={value ? value : values[id]}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "contact_info"}
        disabled={invMod === "delete" ? true : disabled}
        error={error}
      />
    </Grid >
  )

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <Grid container className="GridContainer">
          <GridHeader h4={`${invMod === "edit" ? "Update BOL Weights" : "Delete This Weight?"}`}>
            <Grid container>
              <Grid item xs={11} sm={5} md={5} className="GridItem">
                <Autocomplete
                  id="inventory_location"
                  options={inventoryData}
                  onChange={(_, inv_id) => setValue("inventory_id", inv_id)}
                  value={values["inventory_id"]}
                  disabled={invMod === "delete"}
                  renderInput={params => <TextField
                    {...params}
                    id="inventory_id"
                    label="Inventory ID"
                    value={values["inventory_id"]}
                    margin="normal"
                    required
                  />}
                />
              </Grid>
              {textFields.map(field => makeField(field))}
            </Grid>
            {invMod === "edit" ?
              <Button
                color="primary"
                type="button"
                onClick={handleSubmit}
              >
                Update
              </Button>
              :
              <><Button
                color="white"
                type="button"
                onClick={handleSubmit}
                style={{ backgroundColor: "#ef5350", color: "white", padding: "9px 20px", margin: "0 20px" }}
              >
                Yes, Delete
              </Button>
                <Button
                  color="white"
                  type="button"
                  onClick={handleClose}
                  style={{ padding: "9px 20px", margin: "0 20px" }}
                >
                  No, Cancel
                </Button></>}
          </GridHeader>
        </Grid>
      </Dialog>
    </div>
  )
}

export default PurchaseOrderEdit