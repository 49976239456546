import axios from "axios"
import { forwardRef, useState, useEffect, useCallback } from 'react'
import { IconButton, Typography, CircularProgress } from "@material-ui/core"
import { GetApp } from "@material-ui/icons"
import { CSVLink } from "react-csv"
import useStyles from "assets/jss/material-dashboard-react/components/headerStyle"

const GQLendpoint = localStorage.getItem("REACT_APP_GRAPHQL_ENDPOINT")
const GQLendpointDirect = localStorage.getItem("LOGITRAC_API_GRAPHQL_ENDPOINT")
const restAPI = localStorage.getItem("LOGITRAC_API_URL")

export const createDateString = (timestampString) => {
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZoneName: 'short' }
  return new Date(Date.parse(timestampString)).toLocaleDateString(undefined, dateOptions);
}

export const commas = rawValue => {
  const valueNum = typeof rawValue === "number" ? rawValue : Number(rawValue)
  const valueString = typeof rawValue === "string" ? rawValue : String(rawValue)
  return valueNum < 0 ?
    "-" + valueString.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    :
    valueString.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const noCommas = rawValue => Number(parseFloat((typeof rawValue === "string" ? rawValue : String(rawValue)).replace(/,/g, "")))

export const useForm = initialValues => {
  const [values, setCurrVals] = useState(initialValues);
  const [initVals, setInitVals] = useState(initialValues);

  const setValue = useCallback(
    (key, val) => setCurrVals((prev) => ({ ...prev, [key]: val })),
    [setCurrVals],
  )

  const setValues = useCallback(
    (values) => setCurrVals((prev) => ({ ...prev, ...values })),
    [setCurrVals],
  )

  const setInitialValues = useCallback(
    (values) => {
      setCurrVals(values);
      setInitVals(values);
    },
    [setCurrVals, setInitVals],
  )

  const resetValues = useCallback(
    () => setCurrVals(initVals),
    [setCurrVals, initVals],
  )

  const isDirty = !(JSON.stringify(values) === JSON.stringify(initVals))

  return {
    values,
    initVals,
    setValue,
    setValues,
    setInitialValues,
    resetValues,
    isDirty
  }
}

export const useGqlApi = (query, variables) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const res = await axios.post(
          new URL(GQLendpoint),
          JSON.stringify({ query, variables }),
          { cancelToken: source.token },
        )
        setLoading(false)
        setData(res.data.data)
        setError(res.data.errors?.map(err => err.message))
      } catch (error) {
        setLoading(false)
        setError([error.message])
      }

      return () => source.cancel()
    })()
  }, [query, variables])

  return { data, loading, error }
}

export const useLazyGqlDirect = query => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const runFunc = async variables => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    try {
      const res = await axios.post(
        new URL(GQLendpointDirect),
        { query, variables },
        { cancelToken: source.token },
      )
      setLoading(false)
      setData(res.data.data)
      setError(res.data.errors?.map(err => err.message))
      return res.data.data
    } catch (error) {
      console.log('error: ', error);
      setLoading(false)
      setError([error.message])
    }

    return () => source.cancel()
  }

  return [runFunc, { data, loading, error }]
}

export const useGqlDirect = (query, variables) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const res = await axios.post(
          new URL(GQLendpointDirect),
          { query, variables },
          { cancelToken: source.token },
        )
        setLoading(false)
        setData(res.data.data)
        setError(res.data.errors?.map(err => err.message))
      } catch (error) {
        setLoading(false)
        setError([error.message])
      }

      return () => source.cancel()
    })()
  }, [query, variables])

  return { data, loading, error }
}

export const useRestApi = (endpoint, body) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const res = await axios.get(
          new URL(`${restAPI}${endpoint}`),
          body,
          { cancelToken: source.token },
        )
        setLoading(false)
        setData(res.data)
        setError(res.data.errors?.map(err => err.message))
      } catch (error) {
        setLoading(false)
        setError([error.message])
      }

      return () => source.cancel()
    })()
  }, [endpoint, body])

  return { data, loading, error }
}

export const useRestApiPost = (endpoint, body) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const res = await axios.post(
          new URL(`${restAPI}${endpoint}`),
          body,
          { cancelToken: source.token },
        )
        setLoading(false)
        setData(res.data)
        setError(res.data.errors?.map(err => err.message))
      } catch (error) {
        setLoading(false)
        setError([error.message])
      }

      return () => source.cancel()
    })()
  }, [endpoint, body])

  return { data, loading, error }
}

export const useLazyRestApi = (endpoint, body) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const runFunc = async () => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    try {
      const res = await axios.get(
        new URL(`${restAPI}${endpoint}`),
        body,
        { cancelToken: source.token },
      )
      setLoading(false)
      setData(res.data)
      setError(res.data.errors?.map(err => err.message))
      return res.data
    } catch (error) {
      console.log('error: ', error);
      setLoading(false)
      setError([error.message])
    }

    return () => source.cancel()
  }

  return [runFunc, { data, loading, error }]
}

export const useLazyRestApiPost = endpoint => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const runFunc = async body => {
    setLoading(true)
    setData(null)
    setError(null)
    const source = axios.CancelToken.source();
    try {
      const res = await axios.post(
        new URL(`${restAPI}${endpoint}`),
        body,
        { cancelToken: source.token },
      )
      setLoading(false)
      setData(res.data)
      setError(res.data.errors?.map(err => err.message))
      return res.data
    } catch (error) {
      console.log('error: ', error);
      setLoading(false)
      setError([error.message])
    }

    return () => source.cancel()
  }

  return [runFunc, { data, loading, error }]
}

export const CSVButton = forwardRef(({ data, fetchCSVData, filename, label, loading, disabled }, ref) => {
  const classes = useStyles()
  if (data && data.length > 0) {
    return <>
      <div className={`${classes.flex} ${classes.iconAlign}`}>
        <IconButton color="primary" disabled={disabled}>
          <GetApp />
        </IconButton>
        <Typography>{label}</Typography>
      </div>
      <CSVLink
        ref={ref}
        className="btn btn-secondary"
        target="_self"
        filename={filename}
        data={data}
      />
    </>
  } else {
    return <div className={`${classes.flex} ${classes.iconAlign}`}>
      <IconButton color="primary" disabled={disabled} onClick={fetchCSVData}>
        {loading ? <CircularProgress /> : <GetApp />}
      </IconButton>
      <Typography>{label}</Typography>
    </div>
  }
})

export const useDebounce = (func, delay) => {
  const [id, setId] = useState(null)

  return useCallback((...args) => {
    id && clearTimeout(id)

    setId(setTimeout(() => {
      setId(null)
      func(...args)
    }, delay))
  }, [func, delay, id])
}