import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Grid, CircularProgress } from "@material-ui/core"
import SettingsLinks from "components/Settings/SettingsLinks"
import SettingsComponents from "components/Settings/SettingsComponents"
import Users from "components/Settings/Users"
import Name from "components/Settings/Name"
import WeighmasterLicense from "components/Settings/WeighmasterLicense"
import SettingsTable from "components/Settings/SettingsTable"
import GridHeader from "components/common/GridHeader"
import ErrorDialog from "components/common/ErrorDialog"
import { queries } from "queries"
import { useLazyGqlDirect, useGqlDirect } from "helpers"
import { actionGenerators } from "reducer"
import { ReducerContext } from "App"

const SettingsView = () => {
  const { data: allConstsData, loading: allConstLoading, error: allConstError } = useGqlDirect(queries.GET_ALL_CONST)
  const [getAllConst, { data: getAllConstData }] = useLazyGqlDirect(queries.GET_ALL_CONST)
  const [getAllUsers, { data: getAllUsersData }] = useLazyGqlDirect(queries.GET_ALL_USERS)

  const { dispatch, state: { user: { permissions } } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])


  const [developerOpen, setDeveloperOpen] = useState(false)
  const [adminOpen, setAdminOpen] = useState(false)
  const [managerOpen, setManagerOpen] = useState(false)
  const [userOpen, setUserOpen] = useState(false)
  const [settingIndex, setSettingIndex] = useState(null)

  useEffect(() => {
    getAllConstData && dispatch(actionGenerators.setAllConsts(getAllConstData))
  }, [getAllConstData, dispatch])

  useEffect(() => {
    getAllUsersData && (() => {
      dispatch(actionGenerators.update({ allUsers: getAllUsersData.allUsers }))
    })()
  }, [getAllUsersData, dispatch])

  useEffect(() => {
    allConstsData && (() => dispatch(actionGenerators.setAllConsts(allConstsData)))()
  }, [allConstsData, dispatch])


  const settingsMatrix = [
    {
      label: "Developer",
      enable: permissions.includes("developer"),
      isOpen: developerOpen,
      open: () => setDeveloperOpen(true),
      close: () => setDeveloperOpen(false),
      settings: [] // settings: ["Test Roles", "Disable User", "Force Error"]
    },
    {
      label: "Admin",
      enable: permissions.includes("admin"),
      isOpen: adminOpen,
      open: () => setAdminOpen(true),
      close: () => setAdminOpen(false),
      settings: [
        { label: "Users", Component: Users },
        { label: "Terminals", Component: SettingsTable, props: { category: "terminals", categoryTitle: "Terminal" } }
      ],
      maxWidth: "xl"
    },
    {
      label: "Manager",
      enable: permissions.includes("manager"),
      isOpen: managerOpen,
      open: () => setManagerOpen(true),
      close: () => setManagerOpen(false),
      settings: [
        { label: "Sand Types", Component: SettingsTable, props: { category: "sandType", categoryTitle: "Sand Type" } },
        { label: "Transload Clients", Component: SettingsTable, props: { category: "clientName", categoryTitle: "Transload Client" } }
      ]
    },
    {
      label: "User",
      enable: permissions.includes("user"),
      isOpen: userOpen,
      open: () => setUserOpen(true),
      close: () => setUserOpen(false),
      settings: [
        { label: "Name", Component: Name },
        { label: "Weighmaster License", Component: WeighmasterLicense }
      ]
    }
  ]

  const handleClick = (permissionIndex, settingIndex) => {
    setSettingIndex(settingIndex)
    settingsMatrix[permissionIndex].open()
  }

  return (
    <>
      {allConstLoading && <CircularProgress />}
      {allConstError?.map(err => <ErrorDialog error={err} />)}
      {allConstsData &&
        <>
          <Grid container className="GridContainer">
            <GridHeader h4="Settings" p="Edit Available Settings">
              {settingsMatrix.flatMap((props, ind) => props.enable ? <SettingsLinks {...props} permissionIndex={ind} onClick={handleClick} key={ind} /> : [])}
            </GridHeader>
          </Grid>
          {settingsMatrix.flatMap((props, ind) => props.isOpen ? <SettingsComponents {...props} getAllConst={getAllConst} getAllUsers={getAllUsers} settingIndex={settingIndex} key={ind} /> : [])}
        </>
      }</>
  )
}

export default SettingsView
