import React, { useState } from 'react'
import { Tabs, Tab, Box, Dialog, Grid } from '@material-ui/core'
import GridHeader from "components/common/GridHeader"

const TabPanel = ({ children, value, index }) => value === index && <Box>{children}</Box>

const SettingsComponents = ({ label, isOpen, close, settings, settingIndex, maxWidth, getAllConst, getAllUsers }) => {
  const [value, setValue] = useState(settingIndex)

  return <>
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <Grid container>
        <GridHeader h4={`${label} Settings`}>
          <Tabs
            value={value}
            onChange={(_, val) => setValue(val)}
            indicatorColor="primary"
            textColor="primary"
          >
            {settings.map(({ label }, ind) => <Tab label={label} key={ind} />)}
          </Tabs>
          {settings.map(({ Component, props }, ind) => <TabPanel value={value} index={ind} key={ind}><Component handleClose={close} {...props} getAllConst={getAllConst} getAllUsers={getAllUsers} /></TabPanel>)}
        </GridHeader>
      </Grid>
    </Dialog>
  </>
}

export default SettingsComponents