import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { TextField, CircularProgress, InputLabel, Select, FormControl, MenuItem, Grid } from "@material-ui/core"
import Button from "components/common/Button"
import ErrorDialog from "components/common/ErrorDialog"
import GridHeader from "components/common/GridHeader"
import { queries } from "queries"
import { commas, noCommas, useForm, useGqlDirect } from "helpers"
import { ReducerContext } from "App"
import axios from "axios"

const init = {
  po_id: "",
  sand_type: "",
  buyer: "",
  transloading_client: "",
  job_name: "",
  external_reference_number: "",
  driver_name: "",
  truck_number: "",
  trailer_number: "",
  carrier: "",
  tare_weight: "",
  notes: ""
}

const BOLCreate = () => {
  const { state: { user: { id: dbUserId, permissions }, currentTerminal } } = useContext(ReducerContext)
  const history = useHistory()

  useEffect(() => { !permissions.includes('user') && history.push("/unauthorized") }, [permissions, history])

  const { setValue, values } = useForm(init)

  const { loading, error, data } = useGqlDirect(queries.GET_ACTIVE_PO_CREATE_BOL)

  const handlePOSelect = e => {
    const [selectedPO] = data.pos.filter(po => (po.id === e.target.value) && (po.terminal === currentTerminal))
    const { id, buyer, sand_type, transloading_client, external_reference_number, job_name } = selectedPO
    Object.entries({ po_id: id, buyer, sand_type, transloading_client, external_reference_number, job_name }).map(([key, val]) => setValue(key, val))
  }

  const handleForm = async e => {
    e.preventDefault()
    const { tare_weight, buyer, sand_type, transloading_client, external_reference_number, job_name, ...rest } = values

    const item = {
      ...rest,
      tare_weight: noCommas(tare_weight),
      user: dbUserId,
      terminal: currentTerminal
    }

    try {
      await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/insert`,
        {
          item,
          dbTable: "Bills_Of_Lading",
          dbEvent: "insert",
          dbUserId
        }
      )
      history.push("/outbound")
    } catch (error) {
      console.log('error: ', error);
    }
  }

  const textFields = [
    { disabled: true, width: 6, id: "sand_type", label: "Product" },
    { disabled: true, width: 6, id: "buyer", label: "Buyer" },
    { disabled: true, width: 6, id: "transloading_client", label: "Transloading Client" },
    { disabled: true, width: 6, id: "job_name", label: "Job Name" },
    { disabled: true, width: 6, id: "external_reference_number", label: "External Reference Number" },
    { disabled: false, width: 12, id: "driver_name", label: "Driver's Full Name" },
    { disabled: false, width: 6, id: "truck_number", label: "Truck Number" },
    { disabled: false, width: 6, id: "trailer_number", label: "Trailer Number" },
    { disabled: false, width: 12, id: "carrier", label: "Carrier Name" },
    {
      disabled: false,
      width: 12,
      id: "tare_weight",
      label: "Tare Weight",
      onChange: e => setValue(e.target.id, commas(e.target.value)),
      error: (noCommas(values["tare_weight"]) < 15000 || noCommas(values["tare_weight"]) > 80000),
      helperText: noCommas(values["tare_weight"]) < 15000 ? "Tare Weight must be greater than 15,000 lbs" : noCommas(values["tare_weight"]) > 80000 ? "Tare Weight must be less than 80,000 lbs" : ""
    },
    { disabled: false, width: 12, id: "notes", label: "Notes" }
  ]

  const makeField = ({ id, label, width, disabled, onChange, error, helperText }) => (
    <Grid item xs={12} sm={width} md={width} className="GridItem" key={id}>
      <TextField
        id={id}
        label={label}
        value={values[id]}
        onChange={onChange ? onChange : e => setValue(e.target.id, e.target.value)}
        margin="normal"
        fullWidth
        required={id !== "notes"}
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
    </Grid >
  )
  return (
    <Grid container className="GridContainer">
      <GridHeader h4="Start New BOL" p="Complete all details below.">
        {loading && <CircularProgress />}
        {error && <ErrorDialog error={"error"} />}
        {data && <form onSubmit={handleForm}>
          <Grid container className="GridContainer">
            <Grid item xs={12} sm={6} md={6} className="GridItem">
              <FormControl fullWidth required style={{ margin: "16px 0 8px" }}>
                <InputLabel>Purchase Order Number</InputLabel>
                <Select
                  name="po_id"
                  onChange={handlePOSelect}
                  fullWidth
                  required
                  value={values["po_id"]}
                >
                  {data.pos.filter(po => po.terminal === currentTerminal).map(PurchaseOrder => (
                    <MenuItem key={PurchaseOrder.id} value={PurchaseOrder.id}>
                      {PurchaseOrder.purchase_order_number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {textFields.map(field => makeField(field))}
          </Grid>
          <Button color="primary" type="submit" value="submit" disabled={(noCommas(values["tare_weight"]) < 15000 || noCommas(values["tare_weight"]) > 80000)}>
            Submit BOL
          </Button>
        </form>}
      </GridHeader>
    </Grid>
  )
}

export default BOLCreate
