import React, { useContext } from "react"
import { useLocation, NavLink } from 'react-router-dom'
import { Drawer, Hidden, List, ListItem, ListItemText, Icon } from "@material-ui/core"
import useStyles from "assets/jss/material-dashboard-react/components/sidebarStyle"
import logo from "assets/img/tss_white.png"
import logitracLogo from "assets/img/logitrac_white.png"
import image from "assets/img/sidebar-8.png"
import { authContext } from "indexApp"
import { ReducerContext } from "App"

const Sidebar = ({ routes, toggle, open }) => {
  const classes = useStyles()
  const location = useLocation()
  const { state: { user } } = useContext(ReducerContext)

  const links = (
    <List className={classes.list}>
      <Hidden mdUp>
        <ListItem button className={classes.itemLink}>
          <Icon className={classes.itemIcon}>person</Icon>
          <ListItemText primary={`${user?.first_name} ${user?.last_name}`} className={classes.itemText} disableTypography={true} />
        </ListItem>
      </Hidden>
      {routes.map((prop, key) => (
        <NavLink to={prop.path} className={classes.item} activeClassName="active" key={key}>
          <ListItem button className={`${classes.itemLink} ${prop.path === location.pathname ? classes.green : ""}`}>
            <Icon className={classes.itemIcon}>{prop.icon}</Icon>
            <ListItemText primary={prop.name} className={classes.itemText} disableTypography={true} />
          </ListItem>
        </NavLink>))}
      <ListItem button className={classes.itemLink} onClick={() => authContext.logOut()}>
        <Icon className={classes.itemIcon}>logout</Icon>
        <ListItemText primary="Logout" className={classes.itemText} disableTypography={true} />
      </ListItem>
    </List>
  )

  const brand = (
    <div className={classes.logo}>
      <a href="https://tssands.com">
        <img src={logo} alt="logo" className={classes.img} />
      </a>
      <a href="/">
        <img src={logitracLogo} alt="logo" className={classes.img} />
      </a>
    </div>
  )

  return (
    <div>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          classes={{ paper: classes.drawerPaper }}
          onClose={toggle}
          ModalProps={{ keepMounted: true }}
        >
          <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }} >
          <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} />
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Sidebar
