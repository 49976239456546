import { TablePagination } from '@material-ui/core'

const PatchedPagination = ({
  ActionsComponent,
  onChangePage,
  onChangeRowsPerPage,
  ...tablePaginationProps
}) => {

  return (
    <TablePagination
      {...tablePaginationProps}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        )
      }}
    />
  )
}

export default PatchedPagination