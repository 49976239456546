import React from "react"
import LinearProgress from "@material-ui/core/LinearProgress"
import { lighten, withStyles } from "@material-ui/core/styles"

const railCarMaxCapacity = 230000

const BorderLinearProgress = withStyles({
  root: {
    height: 15,
    backgroundColor: lighten("#1e88e5", 0.5)
  },
  bar: {
    borderRadius: 10,
    borderRadiusBottomleft: 0,
    borderRadiusTopleft: 0,
    backgroundColor: "#1e88e5"
  }
})(LinearProgress)

const Percentage = ({ row, type }) => {
  const { quantity_shipped, max_quantity_to_ship, quantity } = row
  return (
    <div style={{ position: "relative" }}>
      <p style={{
        textAlign: "center",
        marginBottom: "1px",
        position: "absolute",
        bottom: "-13%",
        width: "100%",
        fontSize: "12px",
        zIndex: "200",
        color: "white"
      }}
      >
        {Number((type === "po" ?
          (quantity_shipped / max_quantity_to_ship)
          :
          (quantity / railCarMaxCapacity)
        ) * 100).toPrecision(3)}%
      </p>
      <BorderLinearProgress
        variant="determinate"
        color="secondary"
        value={
          type === "po" ?
            (quantity_shipped / max_quantity_to_ship) * 99.9
            :
            row.quantity < railCarMaxCapacity ?
              (row.quantity / railCarMaxCapacity) * 99.9
              :
              100
        }
      >
      </BorderLinearProgress>
    </div>
  )
}

export default Percentage