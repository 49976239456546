import React from "react"
import { Grid } from "@material-ui/core"
import { Card, CardHeader, CardBody } from "components/common/Card"

const GridHeader = ({ sm = 12, md = 12, xs = 12, h4, p, children }) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} className="GridItem">
      <Card>
        <CardHeader color="primary">
          <h4 className="cardTitleWhite">{h4}</h4>
          <p className="cardCategoryWhite">{p}</p>
        </CardHeader>
        <CardBody>
          {children}
        </CardBody>
      </Card>
    </Grid>
  )
}

export default GridHeader
