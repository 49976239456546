import React, { useReducer, createContext } from 'react'
import { createBrowserHistory } from "history"
import { Router } from "react-router-dom"
import { reducer, initialState } from 'reducer'
import Home from "components/Home"
import "assets/css/material-dashboard-react.css?v=1.7.0"
import "assets/css/styles.css"
import theme from "./assets/jss/material-theme"
import { ThemeProvider } from '@material-ui/core/styles'

const hist = createBrowserHistory();
export const ReducerContext = createContext()

export const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ReducerContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <Router history={hist}>
          <Home />
        </Router>
      </ThemeProvider>
    </ReducerContext.Provider>
  )
}