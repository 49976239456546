import React, { useContext } from "react"
import axios from "axios"
import { TextField, Grid } from "@material-ui/core"
import useStyles from "assets/jss/material-global-styles"
import Button from "components/common/Button"
import { actionGenerators } from "reducer"
import { ReducerContext } from "App"
import { useForm } from "helpers"

const Name = ({ handleClose }) => {
  useStyles()
  const { dispatch, state: { user: { first_name, last_name, id: dbUserId, email }, user } } = useContext(ReducerContext)
  const { setValue, values, isDirty } = useForm({ first_name, last_name })

  const handleSubmit = async () => {
    if (isDirty) {
      try {
        await axios.post(
          `${localStorage.getItem("LOGITRAC_API_URL")}api/update`,
          {
            array: [{ ...values, id: dbUserId }],
            dbTable: "users",
            dbEvent: "update",
            dbUnique: "id",
            dbUserId
          }
        )
        dispatch(actionGenerators.update({ user: { ...user, ...values } }))
      } catch (error) {
        console.log('error: ', error);
      }
    }

    handleClose()
  }

  return (
    <Grid container className="GridContainer">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} className="GridItem">
          <TextField
            id={"first_name"}
            label={"First Name"}
            value={values["first_name"]}
            onChange={({ target: { id, value } }) => setValue(id, value)}
            margin="normal"
            fullWidth
            required
          />
        </Grid >
        <Grid item xs={12} sm={12} md={12} className="GridItem">
          <TextField
            id={"last_name"}
            label={"Last Name"}
            value={values["last_name"]}
            onChange={({ target: { id, value } }) => setValue(id, value)}
            margin="normal"
            fullWidth
            required
          />
        </Grid >
        <Grid item xs={12} sm={12} md={12} className="GridItem">
          <TextField
            id={"email"}
            label={"Email"}
            value={email}
            margin="normal"
            fullWidth
            disabled
          />
        </Grid >
      </Grid>
      <Button color="primary" type="button" onClick={handleSubmit}>Update</Button>
    </Grid>
  )
}

export default Name