import { useState, useContext } from "react"
import { ReducerContext } from "App"
import { v4 as uuidv4 } from 'uuid'
import axios from "axios"

export const useInvTableImport = (getTermInv, sqlParams) => {
  const { state: { user: { id: dbUserId }, constsArrays, currentTerminal } } = useContext(ReducerContext)

  const [csvErrors, setCsvErrors] = useState([])
  const [importCount, setImportCount] = useState(0)

  const checkList = {
    inventory_id: [["null"], ["string"]],
    owner: [["null"], ["string"]],
    storage_type: [["null"], ["string"], ["val", constsArrays.storageType]],
    sand_type: [["null"], ["string"], ["val", constsArrays.sandType]],
    status: [["null"], ["string"], ["val", constsArrays.inventoryStatus]],
    quantity: [["null"], ["num"], ["neg"]],
    source_location: [["null"], ["string"]],
    transloading_client: [["null"], ["string"], ["val", constsArrays.clientName]],
  }

  const checks = {
    null: (header, value) => !value ? { error: `No value for '${header}', cannot be empty.`, value } : [],
    string: (header, value) => typeof value !== "string" ? { error: `Invalid type for '${header}', must be a string.`, value } : [],
    num: (header, value) => isNaN(Number(value)) ? { error: `Invalid type for '${header}', must be a number.`, value } : [],
    neg: (header, value) => value < 0 ? { error: `Invalid value for '${header}', cannot be negative.`, value } : [],
    val: (header, value, array) => !array.includes(value) ? { error: `Invalid value of '${value}' for '${header}', not from allowed values.`, value } : [],
  }

  const handleReadCSV = async csv => {
    const [{ data: headers }, ...dataRaw] = csv

    const data = dataRaw.filter(item => item.data[0] !== "")

    if (data.some(item => item.data.length !== 8)) {
      setCsvErrors([{ row: 0, errors: [`8 columns expected. Expected format is: ${headers.join(', ')}`] }])
      return
    }

    const errorsCheck = data.map(item => Object.fromEntries(item.data.map((entry, ind) => {
      const [error] = checkList[Object.keys(checkList)[ind]].flatMap(([check, arr]) => checks[check](headers[ind], entry, arr))
      return [Object.keys(checkList)[ind], error || (ind === 5 ? Number(entry) : entry)]
    }
    )))

    const errorsList = errorsCheck.reduce((acc, cur, ind) => {
      const errors = Object.entries(cur).filter(([_, value]) => typeof value === "object").map(([_, value]) => value.error)
      return errors.length > 0 ? [...acc, { row: ind + 2, errors }] : acc
    }, [])


    if (errorsList.length > 0) {
      setCsvErrors(errorsList)
      return
    }

    try {
      const updated_at = new Date().toISOString()
      const import_id = uuidv4()
      const result = await axios.post(
        `${localStorage.getItem("LOGITRAC_API_URL")}api/upsert`,
        {
          array: errorsCheck.map(item => ({ ...item, updated_at, import_id, terminal: currentTerminal })),
          dbTable: "Terminal_Inventory",
          dbEvent: "update",
          dbUnique: "inventory_id",
          dbUserId
        }
      )
      setImportCount(result.data.length)
      getTermInv({ ...sqlParams, page_number: 0 })
    } catch (error) {
      console.log('error: ', error);
    }
  }

  return { handleReadCSV, csvErrors, importCount, setCsvErrors, setImportCount }
}