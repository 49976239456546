import React from 'react'
import ReactDOM from "react-dom"
import { runWithAdal } from "react-adal"
import { AuthenticationContext } from "react-adal"

const adalConfig = {
  tenant: localStorage.getItem("REACT_APP_ADAL_TENANTID"),
  clientId: localStorage.getItem("REACT_APP_ADAL_CLIENTID"),
  redirectUri: window.location.protocol + "//" + window.location.host,
  endpoints: { api: "https://graph.microsoft.com" },
  cacheLocation: "localStorage",
  postLogoutRedirectUri: "https://tssands.com/"
}

export const authContext = new AuthenticationContext(adalConfig)

export const start = () => {
  const { App } = require("./App")
  runWithAdal(
    authContext,
    () => ReactDOM.render(
      <App />,
      document.getElementById("root")
    ),
    false //DO_NOT_LOGIN
  )
}
