export const queries = {
  GET_ALL_USERS: `{ allUsers {
    archived_at
    email
    first_name
    inserted_at
    last_name
    permissions
    terminals
    id
    weighmaster_license
    current_terminal } }`,

  GET_ACTIVE_CONST: `{ constants(activeOnly: true) {
    id
    category
    type
    archived_at } }`,

  GET_ALL_CONST: `{ constants(activeOnly: false) {
    id
    category
    type
    archived_at } }`,

  GET_BOLS_RANGE: `query($start_date: DateTime!, $end_date: DateTime!) {
    bols(range: true, createdOrderBy: "asc", withInvInter: false, start_date: $start_date, end_date: $end_date) {
      Purchase_Orders {
        sand_type
        purchase_order_number
        external_reference_number
        job_name
        buyer }
      carrier
      status
      created_at
      driver_name
      gross_weight
      tare_weight
      terminal
      trailer_number
      truck_number
      processed_at
      bolId } }`,

  GET_BOLS_RANGE_W_INV_INTER: `query($start_date: DateTime!, $end_date: DateTime!) {
    bols(range: true, createdOrderBy: "asc", withInvInter: true, start_date: $start_date, end_date: $end_date) {
      Purchase_Orders {
        buyer
        job_name
        supplier
        sand_type
        purchase_order_number }
      Inventory_Interactions {
        inventory_id
        gross_weight }
      bolId
      terminal
      created_at
      processed_at
      carrier
      truck_number
      driver_name
      weighmaster_license
      status
      tare_weight
      gross_weight } }`,

  GET_ACTIVE_PURCHASE_ORDERS: `{ pos(activeOnly: true, orderByKey: "purchase_order_number") {
    id
    supplier
    buyer
    sand_type
    max_quantity_to_ship
    purchase_order_number
    start_time
    end_time
    contact_info
    quantity_shipped
    transloading_client
    external_reference_number
    job_name
    status
    created_at
    updated_at
    terminal } }`,

  GET_ACTIVE_PO_CREATE_BOL: `{ pos(activeOnly: true, orderByKey: "purchase_order_number") {
    id
    supplier
    buyer
    purchase_order_number
    external_reference_number
    job_name
    sand_type
    transloading_client
    terminal } }`,

  GET_SPECIFIC_TERMINAL_INVENTORY_IDS: `query($transloading_client: String!, $sand_type: String!) {
    termInv(spec: true, transloading_client: $transloading_client, sand_type: $sand_type) {
      created_at
      id
      import_id
      inventory_id
      owner
      quantity
      sand_type
      source_location
      status
      storage_type
      terminal
      transloading_client
      updated_at } }`,

  GET_SPEC_INV_INTER: `query($bol_id: Int!) {
    invInters(bol_id: $bol_id) {
      bill_of_lading_id
      created_at
      id
      gross_weight
      inventory_id } }`,
};
