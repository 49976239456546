import React, { useState, useContext, useMemo, useEffect } from "react"
import { IconButton, CircularProgress } from "@material-ui/core"
import { Edit, LockOpen, Lock, SaveAlt } from "@material-ui/icons"
import PurchaseOrderEdit from "components/PurchaseOrder/PurchaseOrderEdit"
import ProgressBar from "components/common/ProgressBar"
import { commas, createDateString, useRestApiPost, useLazyRestApiPost, useDebounce } from "helpers"
import { ReducerContext } from "App"
import MUIDataTable from "mui-datatables"
import ErrorDialog from "components/common/ErrorDialog"

const PurchaseOrdersTable = () => {
  const { state: { currentTerminal } } = useContext(ReducerContext)
  const [specificPoData, setSpecificPoData] = useState(null)
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])

  const initSqlParams = useMemo(() => ({
    search_text: null,
    page_number: 0,
    sort: { column: 'created_at', direction: 'desc' },
    filter: {},
    num_per_page: 10,
    currentTerminal
  }), [currentTerminal])

  const [sqlParams, setSqlParams] = useState(initSqlParams)

  const { data: POsData, loading: POsLoading, error: POsError } = useRestApiPost('api/pos', initSqlParams)

  useEffect(() => {
    POsData && setData(POsData)
  }, [POsData])

  const [getPOs, { data: getPOsData, loading: getPOsLoading, error: getPOsError }] = useLazyRestApiPost('api/pos')

  useEffect(() => {
    getPOsData && setData(getPOsData)
  }, [getPOsData])

  const getPOsDebounced = useDebounce(getPOs, 1000)

  const handleClose = () => setOpen(false)
  const handleClick = rowIndex => {
    setSpecificPoData(data[rowIndex])
    setOpen(true)
  }

  const columns = [
    { label: "Actions", name: "Edit", options: { filter: false, sort: false, empty: true, customBodyRenderLite: (_, rowIndex) => <IconButton onClick={() => handleClick(rowIndex)}><Edit /></IconButton> } },
    { label: "Status", name: "status", options: { customBodyRender: status => status === "open" ? <LockOpen style={{ color: "#4BB04F" }} /> : <Lock style={{ color: "#EF534F" }} /> } },
    { label: "Buyer", name: "buyer" },
    { label: "Supplier", name: "supplier" },
    { label: "Max Quantity to Ship", name: "max_quantity_to_ship", options: { customBodyRenderLite: (_, rowIndex) => commas(data[rowIndex].max_quantity_to_ship) } },
    { label: "Quantity Shipped", name: "quantity_shipped", options: { customBodyRenderLite: (_, rowIndex) => commas(data[rowIndex].quantity_shipped) } },
    { label: "Shipped%", name: "quantity_shipped", options: { customBodyRenderLite: (_, rowIndex) => <ProgressBar row={data[rowIndex]} type="po" /> } },
    { label: "Sand Type", name: "sand_type" },
    { label: "Purchase Order Number", name: "purchase_order_number" },
    { label: "Start Time", name: "start_time", options: { customBodyRenderLite: (_, rowIndex) => (createDateString(data[rowIndex].start_time)) } },
    { label: "End Time", name: "start_time", options: { customBodyRenderLite: (_, rowIndex) => (createDateString(data[rowIndex].end_time)) } },
    { label: "Contact Information", name: "contact_info" },
    { label: "External Ref #", name: "external_reference_number" },
    { label: "Transloading Client", name: "transloading_client" },
    { label: "Job Name", name: "job_name" }
  ]

  const col_names = columns.map(col => col.name)

  const options = {
    selectableRows: 'none',
    searchAlwaysOpen: true,
    print: false,
    viewColumns: false,
    filterType: 'textField',
    serverSide: true,
    page: sqlParams.page_number,
    count: data[0] ? data[0].total_rows : 0,
    rowsPerPage: sqlParams.num_per_page,
    rowsPerPageOptions: [5, 10, 20],
    customToolbar: () => <>{(getPOsLoading || POsLoading) && <CircularProgress />}</>,
    onChangePage: page_number => {
      setSqlParams({ ...sqlParams, page_number })
      getPOs({ ...sqlParams, page_number })
    },
    onChangeRowsPerPage: num_per_page => {
      setSqlParams({ ...sqlParams, num_per_page })
      getPOs({ ...sqlParams, num_per_page })
    },
    onColumnSortChange: (column, direction) => {
      setSqlParams({ ...sqlParams, sort: { column, direction }, page_number: 0 })
      getPOs({ ...sqlParams, sort: { column, direction }, page_number: 0 })
    },
    onFilterChange: (_, filterList) => {
      setSqlParams({ ...sqlParams, filter: Object.fromEntries(filterList.flatMap((item, ind) => item[0] ? [[col_names[ind], item[0]]] : [])), page_number: 0 })
      getPOsDebounced({ ...sqlParams, filter: Object.fromEntries(filterList.flatMap((item, ind) => item[0] ? [[col_names[ind], item[0]]] : [])), page_number: 0 })
    },
    onSearchChange: search_text => {
      setSqlParams({ ...sqlParams, search_text, page_number: 0 })
      getPOsDebounced({ ...sqlParams, search_text, page_number: 0 })
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      const cols = columns.filter(item => item.label !== 'Actions')
      const dataFormatted = data.map(item => {
        const [, status, buyer, supplier, max_quantity_to_ship, quantity_shipped, , ...rest] = item.data
        return { ...item, data: [status, buyer, supplier, max_quantity_to_ship, quantity_shipped, `${((quantity_shipped / max_quantity_to_ship) * 100).toPrecision(3)}%`, ...rest] }
      })

      return buildHead(cols) + buildBody(dataFormatted)
    }
  }

  const components = { icons: { DownloadIcon: SaveAlt } }

  return (
    <>
      {(POsError || getPOsError) && <ErrorDialog error={"error"} />}
      <MUIDataTable title={<CircularProgress />} {...{ data, columns, options, components }} />
      {open && <PurchaseOrderEdit
        open={open}
        handleClose={handleClose}
        specificPoData={specificPoData}
        getPOs={getPOs}
        sqlParams={sqlParams}
      />}
    </>
  )
}

export default PurchaseOrdersTable
