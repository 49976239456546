import React from "react"
import { Dialog, Grid } from "@material-ui/core"
import { ErrorOutline } from "@material-ui/icons"
import { CardBody } from "components/common/Card"

const ErrorDialog = ({ error: { message } }) => {
  return (
    <Dialog open={true}>
      <CardBody className="" style={{ padding: 30 }}>
        <Grid container className="GridContainer">
          <Grid item xs={12} className="GridItem">
            <h3 style={{ fill: '#f44336', fontSize: "30px", color: "#f44336" }} ><ErrorOutline style={{ fontSize: "34px" }} /> An error has occured</h3>
          </Grid>
          <Grid item xs={12} className="GridItem"><p>{message}</p></Grid>
          <Grid item xs={12} className="GridItem">
            <p>If pressing <kbd>Ctrl</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> does not resolve your problem, please contact your system administrator and provide them with a screenshot of this dialog.</p>
          </Grid>
        </Grid>
      </CardBody>
    </Dialog>
  )
}

export default ErrorDialog